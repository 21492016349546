import React, {useEffect, useState, useContext} from 'react';
import {Navigate, Outlet, useLoaderData, useLocation, Link, useNavigate} from 'react-router-dom';
import {StaticOptionsContext, logError, getFullLocation} from '../../helpers'
import {fnGetStaticOptions} from '../../api';
import {MainNavRouter} from '../../routes';
import {saveStateWithKey} from '../../LocalStorage';

import './style.css';

function App() {
  const [staticOptions, setStaticOptions] = useState(useContext(StaticOptionsContext));
  const [showMainNavList, setShowMainNavList] = useState(false);
  const loggedIn = useLoaderData();
  const location = useLocation();
  const navigate = useNavigate();

  function hideMainNav() {
    // hide main nav when viewing quote/invoice statement
    return (
        location.pathname.startsWith('/quotes/') && location.pathname.endsWith('/view')
      )
      ||
      (
        location.pathname.startsWith('/invoices/') && location.pathname.endsWith('/view')
      );
  }

  function logout() {
    saveStateWithKey('X-Api-Key', '');
    navigate('/login');
  }

  useEffect(() => {
    fnGetStaticOptions().then((response) => {
      console.log('static options:\n', response.data);
      if (response.data && response.data.data) {
        setStaticOptions(response.data.data);
      }
    }).catch(logError)
  }, []);

  return (
    <>
      {/* navigate to login it not logged in and pass along destination to return to after login */}
      {!loggedIn ?
        <Navigate to="/login" replace state={{destination: getFullLocation(location)}}/>
        :
        null
      }

      <StaticOptionsContext.Provider value={staticOptions}>
        <div className="App">
          {/* MainNav */}
          {hideMainNav() ?
            null
            :
            <div
              className="main-nav"
              onMouseEnter={() => setShowMainNavList(true)}
              onMouseLeave={() => setShowMainNavList(false)}
            >
              <h2>ONSITE DEMOLITION</h2>
              <div className="list-ctn">
                <div className={`list${showMainNavList ? ' expand' : ''}`}>
                  {MainNavRouter.map((r) => (
                    <Link to={r.path} key={r.path}>{r.display}</Link>
                  ))}
                  <span
                    className="logout-btn"
                    onClick={logout}
                  >
                  logout
                </span>
                </div>
              </div>
            </div>
          }

          <Outlet/>
        </div>
      </StaticOptionsContext.Provider>
    </>
  );
}

export default App;
