import React, {useState, useEffect} from 'react';
import {debounce, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {Link, useLocation} from 'react-router-dom';
import {fnGetLeads, fnAddLead, fnEditLead} from '../../api';
import {
  soKeyLeadStatus,
  logError,
  getFullLocation,
  responseDataExists,
  nowDateString,
  displayDate,
  handleResetDoneCallback,
  handleOnClickSortableTableHeader,
} from '../../helpers';
import StaticOptionDropdown from '../../components/StaticOptionDropdown';
import Filters from '../../components/Filters';
import SortableTableHeader from '../../components/SortableTableHeader';

import './style.css';

export function Leads() {
  const pageName = 'Lead List';

  const defaultLead = () => {
    return {
      lead_id: null,
      name: '',
      company: '',
      email: '',
      phone: '',
      address: '',
      status: '',
      ts_created: nowDateString(),
    }
  };

  const [leads, setLeads] = useState([]);
  const [lead, setLead] = useState(defaultLead());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [sortables, setSortables] = useState({column: null, reset: false});
  const location = useLocation();

  function handleOpenModal(lead) {
    if (!lead) {
      lead = defaultLead();
    } else {
      lead = cloneDeep(lead);
    }

    setLead(lead);
    setModalDetails({...modalDetails, show: true, isEdit: lead.lead_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleLeadFieldUpdate(e) {
    const updatedLead = {...lead};
    updatedLead[e.target.name] = e.target.value;
    setLead(updatedLead);
  }

  function getLeads(filters = {search: ''}) {
    const data = {search: filters.search};
    if (filters.fromTs && filters.toTs) {
      data.filter_from_ts = filters.fromTs;
      data.filter_to_ts = filters.toTs;
    }

    fnGetLeads(data).then((response) => {
      console.log('leads:\n', response.data);
      if (responseDataExists(response)) {
        // reset sortable table headers
        handleOnClickSortableTableHeader();

        setLeads(response.data.data || []);
      }
    }).catch(logError)
  }

  function addLead() {
    if (lead) {
      fnAddLead(lead).then((response) => {
        console.log('add lead:\n', response.data);
        if (response.data && response.data.data) {
          setLeads([response.data.data, ...leads]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function editLead() {
    if (lead) {
      fnEditLead(lead.lead_id, lead).then((response) => {
        console.log('edit lead:\n', response.data);
        if (response.data && response.data.data) {
          setLeads(leads.map((l) => l.lead_id === lead.lead_id ? response.data.data : l));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  const delayGetLeads = debounce((...args) => getLeads(...args), 1000);

  useEffect(() => {
    getLeads();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>{pageName}</h1>
        <div className="right">
          <Filters
            onChangeSearch={delayGetLeads}
            onChangeDateRange={getLeads}
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Name</th>
            <th>Company Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Project History</th>
            <th>Email Logs</th>
            <th>Project Requirements</th>
            <th>Follow Up Actions</th>
            <SortableTableHeader
              list={leads}
              getCompareElement={(l) => l ? new Date(l.ts_created).getTime() : null}
              setList={setLeads}
              reset={sortables.column !== 'added' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('added', sortables, setSortables)}
            >
              Added
            </SortableTableHeader>
          </tr>
          </thead>
          <tbody>
          {leads.map((lead, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(lead)}>{lead.name}</td>
              <td onClick={() => handleOpenModal(lead)}>{lead.company}</td>
              <td onClick={() => handleOpenModal(lead)}>{lead.email}</td>
              <td onClick={() => handleOpenModal(lead)}>{lead.phone}</td>
              <td onClick={() => handleOpenModal(lead)}>{lead.address}</td>
              <td>
                <Link
                  to={`/leads/${lead.lead_id}/project_history`}
                  state={{back: getFullLocation(location), backName: pageName, client: lead.name}}
                >
                  History
                </Link>
              </td>
              <td></td>
              <td>
                <Link
                  to={`/leads/${lead.lead_id}/requirements`}
                  state={{back: getFullLocation(location), backName: pageName}}
                >
                  requirements
                </Link>
              </td>
              <td onClick={() => handleOpenModal(lead)}>{lead.status}</td>
              <td onClick={() => handleOpenModal(lead)}>{displayDate(lead.ts_created)}</td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Lead</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleLeadFieldUpdate}
              value={lead.name}
            />
          </div>
          <div>
            <label htmlFor="company">Company:</label>
            <input
              type="text"
              name="company"
              onChange={handleLeadFieldUpdate}
              value={lead.company}
            />
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              name="email"
              onChange={handleLeadFieldUpdate}
              value={lead.email}
            />
          </div>
          <div>
            <label htmlFor="phone">Phone:</label>
            <input
              type="text"
              name="phone"
              onChange={handleLeadFieldUpdate}
              value={lead.phone}
            />
          </div>
          <div>
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              name="address"
              onChange={handleLeadFieldUpdate}
              value={lead.address}
            />
          </div>
          <div>
            <label htmlFor="status">Status:</label>
            <StaticOptionDropdown
              name="status"
              value={lead.status}
              onSelect={handleLeadFieldUpdate}
              staticOptionKey={soKeyLeadStatus}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editLead : addLead}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Leads;
