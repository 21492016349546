import React, {useState, useEffect, useRef} from 'react';
import {debounce, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {useParams, useLocation, Link} from 'react-router-dom';
import {
  fnGetProjectDocs,
  fnAddProjectDoc,
  fnGetProjectDoc,
  fnDeleteProjectDoc,
  fnEditProjectDoc,
} from '../../api';
import {
  logError,
  displayDate,
  displayTime,
  nowDateString,
  responseDataExists,
  displayYesNo,
  routeParamProjectID,
} from '../../helpers';
import YesNoDropdown from '../../components/YesNoDropdown';
import ConfirmationModal from '../../components/ConfirmationModal';
import Filters from '../../components/Filters';

import './style.css';

export function ProjectDocuments() {
  const defaultProjectDocument = () => {
    return {
      document_id: null,
      name: '',
      is_schedule: false,
      ts_created: nowDateString(),
    }
  };

  const params = useParams();
  const location = useLocation();

  const [docs, setDocs] = useState([]);
  const [doc, setDoc] = useState(defaultProjectDocument());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const fileInput = useRef(null);

  function handleOpenModal(doc) {
    if (!doc) {
      doc = defaultProjectDocument();
    } else {
      doc = cloneDeep(doc);
    }

    setDoc(doc);
    setModalDetails({...modalDetails, show: true, isEdit: doc.document_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleOpenConfirmModal(doc) {
    if (doc) {
      setDoc(doc);
      setShowConfirmModal(true);
    }
  }

  function handleCloseConfirmModal() {
    setShowConfirmModal(false);
  }

  function handleDocFieldUpdate(e) {
    const updatedDoc = {...doc};
    const name = e.target.name;
    let value = e.target.value;

    switch (name) {
      case 'file':
        value = e.target.files[0];

        // default file name
        if (!updatedDoc.name) {
          updatedDoc.name = fileInput.current.files[0].name;
        }
        break;
      default:
    }

    updatedDoc[name] = value;
    setDoc(updatedDoc);
  }

  function getDocs(filters = {search: ''}) {
    const data = {search: filters.search};
    if (filters.fromTs && filters.toTs) {
      data.filter_from_ts = filters.fromTs;
      data.filter_to_ts = filters.toTs;
    }

    fnGetProjectDocs(params[routeParamProjectID], data).then((response) => {
      console.log(`project docs:\n`, response.data);
      if (responseDataExists(response)) {
        setDocs(response.data.data || []);
      }
    }).catch(logError)
  }

  function addDoc() {
    if (doc && doc.file) {
      const data = new FormData();
      data.append('file', doc.file);
      data.append('name', doc.name || 'doc');
      data.append('is_schedule', doc.is_schedule);

      fnAddProjectDoc(params[routeParamProjectID], data).then((response) => {
        console.log(`add project doc:\n`, response.data);
        if (response.data && response.data.data) {
          setDocs([response.data.data, ...docs]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function editDoc() {
    if (doc && doc.document_id) {
      fnEditProjectDoc(params[routeParamProjectID], doc.document_id, doc).then((response) => {
        console.log('edit project doc:\n', response.data);
        if (response.data && response.data.data) {
          setDocs(docs.map((d) => d.document_id === doc.document_id ? response.data.data : d));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function downloadDoc(doc = {}) {
    if (doc && doc.document_id) {
      fnGetProjectDoc(params[routeParamProjectID], doc.document_id).then((response) => {
        if (response.data && response.data.data) {
          console.log('download project doc:\n', response.data);
          window.open(response.data.data, '_blank');
        }
      })
    }
  }

  function deleteDoc(doc) {
    if (doc && doc.document_id) {
      fnDeleteProjectDoc(params[routeParamProjectID], doc.document_id).then(() => {
        console.log('delete project doc:\n', doc);
        setDocs(docs.filter((d) => d.document_id !== doc.document_id));
        handleCloseConfirmModal()
      }).catch(logError);
    } else {
      handleCloseConfirmModal()
    }
  }

  const delayGetDocs = debounce((...args) => getDocs(...args), 1000);

  useEffect(() => {
    getDocs();
  }, []);

  return (
    <div>
      <div className="page-header">
        <div className="left">
          {location && location.state && location.state.back ?
            <Link
              className="back-link"
              to={location.state.back}
            >
              {location.state.backName ? location.state.backName : 'back'}
            </Link>
            :
            null
          }
          <h1>Project Documents</h1>
        </div>
        <div className="right">
          <Filters
            onChangeSearch={delayGetDocs}
            onChangeDateRange={getDocs}
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>File Name</th>
            <th>Time</th>
            <th>Is Schedule</th>
            <th>Download</th>
            <th>Delete</th>
          </tr>
          </thead>
          <tbody>
          {docs.map((doc, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(doc)}>{doc.name}</td>
              <td onClick={() => handleOpenModal(doc)}>
                {`${displayDate(doc.ts_created)} ${displayTime(doc.ts_created, true)}`}
              </td>
              <td onClick={() => handleOpenModal(doc)}>{displayYesNo(doc.is_schedule === true)}</td>
              <td>
                <span
                  className="btn-download"
                  onClick={() => downloadDoc(doc)}
                >
                  download
                </span>
              </td>
              <td>
                <span
                  className="btn-clear"
                  onClick={() => handleOpenConfirmModal(doc)}
                >
                  ❌
                </span>
              </td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Document</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!modalDetails.isEdit ?
            <div>
              <label htmlFor="file">File:</label>
              <input
                type="file"
                name="file"
                onChange={handleDocFieldUpdate}
                ref={fileInput}
              />
            </div>
            :
            null
          }
          <div>
            <label htmlFor="name">File Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleDocFieldUpdate}
              value={doc.name}
            />
          </div>
          <div>
            <label htmlFor="is_schedule">Is Schedule:</label>
            <YesNoDropdown
              name="is_schedule"
              onSelect={handleDocFieldUpdate}
              value={doc.is_schedule}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editDoc : addDoc}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmModal}
        title="Delete Project Document?"
        messages={[`Please confirm you want delete ${doc.name}?`]}
        onOK={() => deleteDoc(doc)}
        onCancel={handleCloseConfirmModal}
      />
    </div>
  )
}

export default ProjectDocuments;
