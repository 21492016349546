import React from 'react';
import PropTypes from 'prop-types';
import {orderBy} from 'lodash';

import './style.css';

class SortableTableHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: null, // [null, 'asc', 'desc']
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.order !== null && !prevProps.reset && this.props.reset === true) {
      this.setState({order: null});
      if (this.props.resetDoneCallback) {
        this.props.resetDoneCallback();
      }
    }
  }

  handleOnClick = () => {
    const asc = 'asc';
    const desc = 'desc';

    let {order} = this.state;
    switch (order) {
      case null:
        order = asc;
        break;
      case asc:
        order = desc;
        break;
      case desc:
        order = asc;
        break;
      default:
        console.log(`invalid order value "${order}"`);
    }

    if (order) {
      this.setState({order});

      const {list, getCompareElement, setList, onClick} = this.props;

      if (list && getCompareElement && setList) {
        setList(orderBy(list, getCompareElement, order))
      }

      if (onClick) {
        onClick();
      }
    }
  };

  render() {
    return (
      <th onClick={this.handleOnClick}>
        <div className={`sortable-th ${this.state.order ? this.state.order : ''}`}>
          {this.props.children}
          <div className="arrows"/>
        </div>
      </th>
    )
  }
}

SortableTableHeader.propTypes = {
  list: PropTypes.array,
  getCompareElement: PropTypes.func, // func(list[i]) => list[i] || list[i].field
  setList: PropTypes.func, // func(list)
  reset: PropTypes.bool,
  resetDoneCallback: PropTypes.func,
  onClick: PropTypes.func,
};

export default SortableTableHeader;