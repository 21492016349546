import React, {useState, useEffect} from 'react';
import {sortBy, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {
  fnGetTimeTracking,
  fnAddTimeTracking,
  fnEditTimeTracking,
  fnDeleteTimeTracking,
  fnGetProjects,
  fnGetTeamMembers,
} from '../../api';
import {
  logError,
  nowLocaleDateString,
  displayDate,
  responseDataExists,
  displayTeamMemberOption,
  displayProjectOption,
  displayTime,
} from '../../helpers';
import DatePicker from '../../components/DatePicker';
import ConfirmationModal from '../../components/ConfirmationModal';
import SearchDropdown from '../../components/SearchDropdown';
import Filters from '../../components/Filters';
import MyTimePicker from '../../components/MyTimePicker';

import './style.css';

export function TimeTracking() {
  const defaultTimeTracker = () => {
    // generate most recent passed Sunday including today
    let recentSunday = nowLocaleDateString();
    recentSunday = new Date(recentSunday);
    recentSunday.setUTCDate(recentSunday.getUTCDate() - recentSunday.getUTCDay());
    recentSunday = recentSunday.toJSON();

    return {
      project_id: null,
      member_id: null,
      start: recentSunday,
      sunday_start: null,
      sunday_end: null,
      sunday_lunch: 0,
      monday_start: null,
      monday_end: null,
      monday_lunch: 0,
      tuesday_start: null,
      tuesday_end: null,
      tuesday_lunch: 0,
      wednesday_start: null,
      wednesday_end: null,
      wednesday_lunch: 0,
      thursday_start: null,
      thursday_end: null,
      thursday_lunch: 0,
      friday_start: null,
      friday_end: null,
      friday_lunch: 0,
      saturday_start: null,
      saturday_end: null,
      saturday_lunch: 0,
      member_name: '',
      project_name: '',
    }
  };

  const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const [timeTrackers, setTimeTrackers] = useState([]);
  const [timeTracker, setTimeTracker] = useState(defaultTimeTracker());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);

  function handleOpenModal(timeTracker) {
    if (!timeTracker) {
      timeTracker = defaultTimeTracker();
    } else {
      timeTracker = cloneDeep(timeTracker);
    }

    setTimeTracker(timeTracker);
    setModalDetails({...modalDetails, show: true, isEdit: timeTracker.project_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleOpenConfirmModal(timeTracker) {
    if (timeTracker) {
      setTimeTracker(timeTracker);
      setShowConfirmModal(true);
    }
  }

  function handleCloseConfirmModal() {
    setShowConfirmModal(false);
  }

  function handleTimeTrackerFieldUpdate(e) {
    const updatedTimeTracker = {...timeTracker};
    const name = e.target.name;
    let value = e.target.value;

    switch (name) {
      case 'project_id':
        if (value.client_name && value.name) {
          updatedTimeTracker.project_name = displayProjectOption(value);
        }

        value = value.project_id;
        break;
      case 'member_id':
        if (value.name) {
          updatedTimeTracker.member_name = value.name;
        }

        value = value.member_id;
        break;
      case 'start':
      case 'sunday_start':
      case 'sunday_end':
      case 'monday_start':
      case 'monday_end':
      case 'tuesday_start':
      case 'tuesday_end':
      case 'wednesday_start':
      case 'wednesday_end':
      case 'thursday_start':
      case 'thursday_end':
      case 'friday_start':
      case 'friday_end':
      case 'saturday_start':
      case 'saturday_end':
        if (value) {
          value = value.toJSON();
        }
        break;
      case 'sunday_lunch':
      case 'monday_lunch':
      case 'tuesday_lunch':
      case 'wednesday_lunch':
      case 'thursday_lunch':
      case 'friday_lunch':
      case 'saturday_lunch':
        if (typeof value === 'string') {
          value = parseFloat(value);
          if (isNaN(value)) {
            value = '';
          }
        }
        break;
      default:
    }

    updatedTimeTracker[name] = value;
    setTimeTracker(updatedTimeTracker);
  }

  function handleTimeTrackerFieldWeekdayClear(weekday) {
    if (week.includes(weekday)) {
      const updatedTimeTracker = {...timeTracker};
      updatedTimeTracker[`${weekday}_start`] = null;
      updatedTimeTracker[`${weekday}_end`] = null;
      updatedTimeTracker[`${weekday}_lunch`] = 0;
      setTimeTracker(updatedTimeTracker);
    } else {
      console.log(`handle time tracker weekday clear invalid weekday "${weekday}"`);
    }
  }

  function getSundayOffset(weekday) {
    let offset = 0;
    switch (weekday) {
      case 'sunday':
        break;
      case 'monday':
        offset = 1;
        break;
      case 'tuesday':
        offset = 2;
        break;
      case 'wednesday':
        offset = 3;
        break;
      case 'thursday':
        offset = 4;
        break;
      case 'friday':
        offset = 5;
        break;
      case 'saturday':
        offset = 6;
        break;
      default:
        console.log(`invalid weekday "${weekday}" in sanitize time tracker`);
        return;
    }

    return offset;
  }

  function sanitizeTimeTracker(timeTracker = {}) {
    const sanitizedTimeTracker = {...timeTracker};
    // set correct dates for weekday start/end times
    const sunday = new Date(sanitizedTimeTracker.start);
    const sundayYear = sunday.getUTCFullYear();
    const sundayMonth = sunday.getUTCMonth();
    const sundayDate = sunday.getUTCDate();

    function setData(weekday = '') {
      const offset = getSundayOffset(weekday);
      if (typeof offset === 'undefined') {
        return;
      }

      let s = null;

      const startKey = `${weekday}_start`;
      if (sanitizedTimeTracker[startKey]) {
        s = new Date(sanitizedTimeTracker[startKey]);
        s.setFullYear(sundayYear, sundayMonth, sundayDate + offset);
        sanitizedTimeTracker[startKey] = s.toJSON();
      }

      const endKey = `${weekday}_end`;
      if (sanitizedTimeTracker[endKey]) {
        const e = new Date(sanitizedTimeTracker[endKey]);
        e.setFullYear(sundayYear, sundayMonth, sundayDate + offset);

        // end time past midnight
        if (s && s.valueOf() > e.valueOf()) {
          e.setFullYear(sundayYear, sundayMonth, sundayDate + offset + 1);
        }

        sanitizedTimeTracker[endKey] = e.toJSON();
      }

      const lunchKey = `${weekday}_lunch`;
      if (typeof sanitizedTimeTracker[lunchKey] === 'string') {
        let l = parseFloat(sanitizedTimeTracker[lunchKey]);
        if (isNaN(l)) {
          l = 0;
        }

        sanitizedTimeTracker[lunchKey] = l;
      }
    }

    week.forEach((weekday) => setData(weekday));

    return sanitizedTimeTracker;
  }

  function getTimeTrackers(filters = {projectID: null}) {
    const data = {project_id: filters.projectID};
    if (filters.fromTs && filters.toTs) {
      data.filter_from_ts = filters.fromTs;
      data.filter_to_ts = filters.toTs;
    }

    fnGetTimeTracking(data).then((response) => {
      console.log('time tracking:\n', response.data);
      if (responseDataExists(response)) {
        setTimeTrackers(response.data.data || []);
      }
    }).catch(logError)
  }

  function addTimeTracker() {
    if (timeTracker) {
      fnAddTimeTracking(sanitizeTimeTracker(timeTracker)).then((response) => {
        console.log('add time tracking:\n', response.data);
        if (response.data && response.data.data) {
          setTimeTrackers([response.data.data, ...timeTrackers]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function editTimeTracker() {
    if (timeTracker) {
      fnEditTimeTracking(sanitizeTimeTracker(timeTracker)).then((response) => {
        console.log('edit time tracking:\n', response.data);
        if (response.data && response.data.data) {
          setTimeTrackers(timeTrackers.map((tt) =>
            tt.project_id === timeTracker.project_id &&
            tt.member_id === timeTracker.member_id &&
            tt.start === timeTracker.start ?
              response.data.data : tt));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function deleteTimeTracker(timeTracker) {
    if (timeTracker) {
      fnDeleteTimeTracking(timeTracker).then(() => {
        console.log('delete time tracking:\n', timeTracker);
        setTimeTrackers(timeTrackers.filter((tt) => tt.project_id !== timeTracker.project_id ||
          tt.member_id !== timeTracker.member_id ||
          tt.start !== timeTracker.start));
        handleCloseConfirmModal()
      }).catch(logError);
    } else {
      handleCloseConfirmModal()
    }
  }

  function getProjectOptions(search = '') {
    fnGetProjects({search: search}).then((response) => {
      console.log('project options:\n', response.data);
      if (responseDataExists(response)) {
        setProjectOptions(
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayProjectOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getTeamMemberOptions(search = '') {
    fnGetTeamMembers({search: search}).then((response) => {
      console.log('team member options:\n', response.data);
      if (responseDataExists(response)) {
        setMemberOptions(
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayTeamMemberOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getTotalHours(timeTracker) {
    let total = 0.0;

    if (timeTracker) {
      week.forEach((w) => {
        const start = timeTracker[`${w}_start`];
        const end = timeTracker[`${w}_end`];
        if (start && end) {
          const millisecondsToHour = 3600 * 1000;

          total += Math.round(((new Date(end)).valueOf() - (new Date(start)).valueOf()) / millisecondsToHour * 100) / 100;
        }
      });
    }

    return Math.round(total * 100) / 100;
  }

  function getTotalLunches(timeTracker) {
    let total = 0.0;

    if (timeTracker) {
      week.forEach((w) => {
        const lunch = timeTracker[`${w}_lunch`];
        if (lunch) {
          total += lunch;
        }
      });
    }

    return Math.round(total * 100) / 100;
  }

  function displayShift(start, end) {
    if (start || end) {
      return (
        <>
          {start ? displayTime(start, true) : '?'} -
          <br/>
          {end ? displayTime(end, true) : '?'}
        </>
      );
    }

    return '';
  }

  function displayWeekdayMonthDateLabel(sunday, weekday) {
    if (sunday && weekday) {
      const offset = getSundayOffset(weekday);
      if (typeof offset === 'undefined') {
        return;
      }

      const d = new Date(sunday);
      d.setFullYear(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate() + offset);
      return `${new Intl.DateTimeFormat('en-US', {weekday: 'short', month: 'short', day: 'numeric'}).format(d)}:`;
    } else if (weekday) {
      return `${weekday.charAt(0).toUpperCase() + weekday.substr(1, 2).toLowerCase()}:`;
    }

    return '';
  }

  useEffect(() => {
    getTimeTrackers();
    getProjectOptions();
    getTeamMemberOptions();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>Time Tracking Table</h1>
        <div className="right">
          <Filters
            onChangeDateRange={getTimeTrackers}
            disabledDay={(date) => date.getUTCDay() !== 0 /* not sunday */}
            showProject
            projectOptions={projectOptions}
            onChangeProject={getTimeTrackers}
            hideSearch
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Project</th>
            <th>Employee</th>
            <th>Total Hours</th>
            <th>Lunches (Mins)</th>
            <th>Week Start</th>
            <th>Sunday</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
            <th>Delete</th>
          </tr>
          </thead>
          <tbody>
          {timeTrackers.map((timeTracker, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(timeTracker)}>{timeTracker.project_name}</td>
              <td onClick={() => handleOpenModal(timeTracker)}>{timeTracker.member_name}</td>
              <td onClick={() => handleOpenModal(timeTracker)}>{getTotalHours(timeTracker)}</td>
              <td onClick={() => handleOpenModal(timeTracker)}>{getTotalLunches(timeTracker)}</td>
              <td onClick={() => handleOpenModal(timeTracker)}>{displayDate(timeTracker.start)}</td>
              <td onClick={() => handleOpenModal(timeTracker)}>
                {displayShift(timeTracker.sunday_start, timeTracker.sunday_end)}
              </td>
              <td onClick={() => handleOpenModal(timeTracker)}>
                {displayShift(timeTracker.monday_start, timeTracker.monday_end)}
              </td>
              <td onClick={() => handleOpenModal(timeTracker)}>
                {displayShift(timeTracker.tuesday_start, timeTracker.tuesday_end)}
              </td>
              <td onClick={() => handleOpenModal(timeTracker)}>
                {displayShift(timeTracker.wednesday_start, timeTracker.wednesday_end)}
              </td>
              <td onClick={() => handleOpenModal(timeTracker)}>
                {displayShift(timeTracker.thursday_start, timeTracker.thursday_end)}
              </td>
              <td onClick={() => handleOpenModal(timeTracker)}>
                {displayShift(timeTracker.friday_start, timeTracker.friday_end)}
              </td>
              <td onClick={() => handleOpenModal(timeTracker)}>
                {displayShift(timeTracker.saturday_start, timeTracker.saturday_end)}
              </td>
              <td><span className="btn-clear" onClick={() => handleOpenConfirmModal(timeTracker)}>❌</span></td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Time Tracking</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!modalDetails.isEdit ?
            <>
              <div>
                <label htmlFor="project_id">Project:</label>
                <SearchDropdown
                  name="project_id"
                  value={timeTracker.project_id}
                  onSelect={handleTimeTrackerFieldUpdate}
                  options={projectOptions}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.project_id}
                />
              </div>
              <div>
                <label htmlFor="member_id">Team Member:</label>
                <SearchDropdown
                  name="member_id"
                  value={timeTracker.member_id}
                  onSelect={handleTimeTrackerFieldUpdate}
                  options={memberOptions}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.member_id}
                />
              </div>
            </>
            :
            null
          }
          <div>
            <label htmlFor="start">Start:</label>
            <DatePicker
              name="start"
              value={timeTracker.start}
              onSelect={handleTimeTrackerFieldUpdate}
              disabledDay={(date) => date.getUTCDay() !== 0 /* not sunday */}
              disabled={modalDetails.isEdit}
            />
          </div>
          <table>
            <thead>
            <tr>
              <th>Date</th>
              <th>Start</th>
              <th>End</th>
              <th>Lunch (Mins)</th>
              <th>Clear</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <label htmlFor="sunday">{displayWeekdayMonthDateLabel(timeTracker.start, 'sunday')}</label>
              </td>
              <td>
                <MyTimePicker
                  name="sunday_start"
                  value={timeTracker.sunday_start || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <MyTimePicker
                  name="sunday_end"
                  value={timeTracker.sunday_end || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <input
                  className="input-number"
                  type="number"
                  name="sunday_lunch"
                  onChange={handleTimeTrackerFieldUpdate}
                  value={timeTracker.sunday_lunch}
                />
              </td>
              <td>
                {timeTracker.sunday_start || timeTracker.sunday_end || timeTracker.sunday_lunch !== 0 ?
                  <span className="btn-clear" onClick={() => handleTimeTrackerFieldWeekdayClear('sunday')}>
                    ❌
                  </span>
                  :
                  null
                }
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="monday">{displayWeekdayMonthDateLabel(timeTracker.start, 'monday')}</label>
              </td>
              <td>
                <MyTimePicker
                  name="monday_start"
                  value={timeTracker.monday_start || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <MyTimePicker
                  name="monday_end"
                  value={timeTracker.monday_end || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <input
                  className="input-number"
                  type="number"
                  name="monday_lunch"
                  onChange={handleTimeTrackerFieldUpdate}
                  value={timeTracker.monday_lunch}
                />
              </td>
              <td>
                {timeTracker.monday_start || timeTracker.monday_end || timeTracker.monday_lunch !== 0 ?
                  <span className="btn-clear" onClick={() => handleTimeTrackerFieldWeekdayClear('monday')}>
                    ❌
                  </span>
                  :
                  null
                }
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="tuesday">{displayWeekdayMonthDateLabel(timeTracker.start, 'tuesday')}</label>
              </td>
              <td>
                <MyTimePicker
                  name="tuesday_start"
                  value={timeTracker.tuesday_start || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <MyTimePicker
                  name="tuesday_end"
                  value={timeTracker.tuesday_end || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <input
                  className="input-number"
                  type="number"
                  name="tuesday_lunch"
                  onChange={handleTimeTrackerFieldUpdate}
                  value={timeTracker.tuesday_lunch}
                />
              </td>
              <td>
                {timeTracker.tuesday_start || timeTracker.tuesday_end || timeTracker.tuesday_lunch !== 0 ?
                  <span className="btn-clear" onClick={() => handleTimeTrackerFieldWeekdayClear('tuesday')}>
                    ❌
                  </span>
                  :
                  null
                }
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="wednesday">{displayWeekdayMonthDateLabel(timeTracker.start, 'wednesday')}</label>
              </td>
              <td>
                <MyTimePicker
                  name="wednesday_start"
                  value={timeTracker.wednesday_start || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <MyTimePicker
                  name="wednesday_end"
                  value={timeTracker.wednesday_end || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <input
                  className="input-number"
                  type="number"
                  name="wednesday_lunch"
                  onChange={handleTimeTrackerFieldUpdate}
                  value={timeTracker.wednesday_lunch}
                />
              </td>
              <td>
                {timeTracker.wednesday_start || timeTracker.wednesday_end || timeTracker.wednesday_lunch !== 0 ?
                  <span className="btn-clear" onClick={() => handleTimeTrackerFieldWeekdayClear('wednesday')}>
                    ❌
                  </span>
                  :
                  null
                }
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="thursday">{displayWeekdayMonthDateLabel(timeTracker.start, 'thursday')}</label>
              </td>
              <td>
                <MyTimePicker
                  name="thursday_start"
                  value={timeTracker.thursday_start || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <MyTimePicker
                  name="thursday_end"
                  value={timeTracker.thursday_end || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <input
                  className="input-number"
                  type="number"
                  name="thursday_lunch"
                  onChange={handleTimeTrackerFieldUpdate}
                  value={timeTracker.thursday_lunch}
                />
              </td>
              <td>
                {timeTracker.thursday_start || timeTracker.thursday_end || timeTracker.thursday_lunch !== 0 ?
                  <span className="btn-clear" onClick={() => handleTimeTrackerFieldWeekdayClear('thursday')}>
                    ❌
                  </span>
                  :
                  null
                }
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="friday">{displayWeekdayMonthDateLabel(timeTracker.start, 'friday')}</label>
              </td>
              <td>
                <MyTimePicker
                  name="friday_start"
                  value={timeTracker.friday_start || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <MyTimePicker
                  name="friday_end"
                  value={timeTracker.friday_end || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <input
                  className="input-number"
                  type="number"
                  name="friday_lunch"
                  onChange={handleTimeTrackerFieldUpdate}
                  value={timeTracker.friday_lunch}
                />
              </td>
              <td>
                {timeTracker.friday_start || timeTracker.friday_end || timeTracker.friday_lunch !== 0 ?
                  <span className="btn-clear" onClick={() => handleTimeTrackerFieldWeekdayClear('friday')}>
                    ❌
                  </span>
                  :
                  null
                }
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="saturday">{displayWeekdayMonthDateLabel(timeTracker.start, 'saturday')}</label>
              </td>
              <td>
                <MyTimePicker
                  name="saturday_start"
                  value={timeTracker.saturday_start || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <MyTimePicker
                  name="saturday_end"
                  value={timeTracker.saturday_end || ''}
                  onSelect={handleTimeTrackerFieldUpdate}
                />
              </td>
              <td>
                <input
                  className="input-number"
                  type="number"
                  name="saturday_lunch"
                  onChange={handleTimeTrackerFieldUpdate}
                  value={timeTracker.saturday_lunch}
                />
              </td>
              <td>
                {timeTracker.saturday_start || timeTracker.saturday_end || timeTracker.saturday_lunch !== 0 ?
                  <span className="btn-clear" onClick={() => handleTimeTrackerFieldWeekdayClear('saturday')}>
                    ❌
                  </span>
                  :
                  null
                }
              </td>
            </tr>
            </tbody>
          </table>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editTimeTracker : addTimeTracker}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmModal}
        title="Delete Time Tracking?"
        messages={[
          'Please confirm you want to delete:',
          `Team Member: ${timeTracker.member_name}`,
          `Project: ${timeTracker.project_name}`,
          `Week: ${displayDate(timeTracker.start)}`,
        ]}
        onOK={() => deleteTimeTracker(timeTracker)}
        onCancel={handleCloseConfirmModal}
      />
    </div>
  )
}

export default TimeTracking;
