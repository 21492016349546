import React, {useState, useEffect} from 'react';
import {debounce, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {fnGetTeamMembers, fnAddTeamMember, fnEditTeamMember, fnDeleteTeamMember} from '../../api';
import {soKeyMemberPositions, logError, displayMoney, responseDataExists} from '../../helpers';
import StaticOptionDropdown from '../../components/StaticOptionDropdown';
import ConfirmationModal from '../../components/ConfirmationModal';
import Filters from '../../components/Filters';

import './style.css';

export function TeamMembers() {
  const defaultMember = () => {
    return {
      member_id: null,
      name: '',
      phone: '',
      address: '',
      pay_rate: 0,
      position: 'PM',
      notes: '',
      is_deleted: false,
    }
  };

  const [members, setMembers] = useState([]);
  const [member, setMember] = useState(defaultMember());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  function handleOpenModal(member) {
    if (!member) {
      member = defaultMember();
    } else {
      member = cloneDeep(member);
    }

    setMember(member);
    setModalDetails({...modalDetails, show: true, isEdit: member.member_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleOpenConfirmModal(member) {
    if (member) {
      setMember(member);
      setShowConfirmModal(true);
    }
  }

  function handleCloseConfirmModal() {
    setShowConfirmModal(false);
  }

  function handleMemberFieldUpdate(e) {
    const updatedMember = {...member};
    updatedMember[e.target.name] = e.target.value;
    setMember(updatedMember);
  }

  function getMembers(filters = {search: ''}) {
    const data = {search: filters.search};

    fnGetTeamMembers(data).then((response) => {
      console.log('team members:\n', response.data);
      if (responseDataExists(response)) {
        setMembers(response.data.data || []);
      }
    }).catch(logError)
  }

  function addMember() {
    if (member) {
      fnAddTeamMember(member).then((response) => {
        console.log('add team member:\n', response.data);
        if (response.data && response.data.data) {
          setMembers([response.data.data, ...members]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function editMember() {
    if (member) {
      fnEditTeamMember(member.member_id, member).then((response) => {
        console.log('edit team member:\n', response.data);
        if (response.data && response.data.data) {
          setMembers(members.map((m) => m.member_id === member.member_id ? response.data.data : m));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function deleteMember(member) {
    if (member && member.member_id) {
      fnDeleteTeamMember(member.member_id).then(() => {
        console.log('delete team member:\n', member);
        setMembers(members.filter((m) => m.member_id !== member.member_id));
        handleCloseConfirmModal()
      }).catch(logError);
    } else {
      handleCloseConfirmModal()
    }
  }

  const delayGetTeamMembers = debounce((...args) => getMembers(...args), 1000);

  useEffect(() => {
    getMembers();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>Team Members</h1>
        <div className="right">
          <Filters
            onChangeSearch={delayGetTeamMembers}
            hideDateRange
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Name</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Pay Rates</th>
            <th>Position</th>
            <th>Notes</th>
            <th>Delete</th>
          </tr>
          </thead>
          <tbody>
          {members.map((member, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(member)}>{member.name}</td>
              <td onClick={() => handleOpenModal(member)}>{member.phone}</td>
              <td onClick={() => handleOpenModal(member)}>{member.address}</td>
              <td onClick={() => handleOpenModal(member)}>{displayMoney(member.pay_rate)}</td>
              <td onClick={() => handleOpenModal(member)}>{member.position}</td>
              <td>{/*TODO*/}</td>
              <td><span onClick={() => handleOpenConfirmModal(member)}>❌</span></td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Team Member</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleMemberFieldUpdate}
              value={member.name}
            />
          </div>
          <div>
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="text"
              name="phone"
              onChange={handleMemberFieldUpdate}
              value={member.phone}
            />
          </div>
          <div>
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              name="address"
              onChange={handleMemberFieldUpdate}
              value={member.address}
            />
          </div>
          <div>
            <label htmlFor="pay_rate">Pay Rate:</label>
            <input
              type="number"
              name="pay_rate"
              onChange={handleMemberFieldUpdate}
              value={member.pay_rate}
            />
          </div>
          <div>
            <label htmlFor="position">Position:</label>
            <StaticOptionDropdown
              name="position"
              value={member.position}
              onSelect={handleMemberFieldUpdate}
              staticOptionKey={soKeyMemberPositions}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editMember : addMember}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmModal}
        title="Delete Team Member?"
        messages={[`Please confirm you want delete ${member.name}?`]}
        onOK={() => deleteMember(member)}
        onCancel={handleCloseConfirmModal}
      />
    </div>
  )
}

export default TeamMembers;
