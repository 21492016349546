import React, {useState, useEffect} from 'react';
import {debounce, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {fnGetProductServices, fnAddProductService, fnEditProductService, fnDeleteProductService} from '../../api';
import {
  logError,
  displayMoney,
  responseDataExists,
  handleResetDoneCallback,
  handleOnClickSortableTableHeader
} from '../../helpers';
import ConfirmationModal from '../../components/ConfirmationModal';
import Filters from '../../components/Filters';
import SortableTableHeader from '../../components/SortableTableHeader';

import './style.css';

export function ProductServices() {
  const defaultProductService = () => {
    return {
      ps_id: null,
      name: '',
      description: '',
      price: 0,
    }
  };

  const [productServices, setProductServices] = useState([]);
  const [productService, setProductService] = useState(defaultProductService());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sortables, setSortables] = useState({column: null, reset: false});

  function handleOpenModal(productService) {
    if (!productService) {
      productService = defaultProductService();
    } else {
      productService = cloneDeep(productService);
    }

    setProductService(productService);
    setModalDetails({...modalDetails, show: true, isEdit: productService.ps_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleOpenConfirmModal(productService) {
    if (productService) {
      setProductService(productService);
      setShowConfirmModal(true);
    }
  }

  function handleCloseConfirmModal() {
    setShowConfirmModal(false);
  }

  function handleProductServiceFieldUpdate(e) {
    const updatedProductService = {...productService};
    updatedProductService[e.target.name] = e.target.value;
    setProductService(updatedProductService);
  }

  function getProductServices(filters = {search: ''}) {
    const data = {search: filters.search};

    fnGetProductServices(data).then((response) => {
      console.log('product services:\n', response.data);
      if (responseDataExists(response)) {
        setProductServices(response.data.data || []);
      }
    }).catch(logError)
  }

  function addProductService() {
    if (productService) {
      fnAddProductService(productService).then((response) => {
        console.log('add product service:\n', response.data);
        if (response.data && response.data.data) {
          setProductServices([response.data.data, ...productServices]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function editProductService() {
    if (productService) {
      fnEditProductService(productService.ps_id, productService).then((response) => {
        console.log('edit produce service:\n', response.data);
        if (response.data && response.data.data) {
          setProductServices(productServices.map((ps) => ps.ps_id === productService.ps_id ? response.data.data : ps));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function deleteProductService(productService) {
    if (productService && productService.ps_id) {
      fnDeleteProductService(productService.ps_id).then(() => {
        console.log('delete product service:\n', productService);
        setProductServices(productServices.filter((ps) => ps.ps_id !== productService.ps_id));
        handleCloseConfirmModal()
      }).catch(logError);
    } else {
      handleCloseConfirmModal()
    }
  }

  const delayGetProductServices = debounce((...args) => getProductServices(...args), 1000);

  useEffect(() => {
    getProductServices();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>Products & Services</h1>
        <div className="right">
          <Filters
            onChangeSearch={delayGetProductServices}
            hideDateRange
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Name</th>
            <th>Description</th>
            <SortableTableHeader
              list={productServices}
              getCompareElement={(ps) => ps ? ps.price : null}
              setList={setProductServices}
              reset={sortables.column !== 'price' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('price', sortables, setSortables)}
            >
              Price
            </SortableTableHeader>
            <th>Delete</th>
          </tr>
          </thead>
          <tbody>
          {productServices.map((productService, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(productService)}>{productService.name}</td>
              <td onClick={() => handleOpenModal(productService)}>{productService.description}</td>
              <td onClick={() => handleOpenModal(productService)}>{displayMoney(productService.price)}</td>
              <td><span onClick={() => handleOpenConfirmModal(productService)}>❌</span></td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Product & Service</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleProductServiceFieldUpdate}
              value={productService.name}
            />
          </div>
          <div>
            <label htmlFor="description">Description:</label>
            <textarea
              name="description"
              onChange={handleProductServiceFieldUpdate}
              value={productService.description}
            />
          </div>
          <div>
            <label htmlFor="price">Price:</label>
            <input
              type="number"
              name="price"
              onChange={handleProductServiceFieldUpdate}
              value={productService.price}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editProductService : addProductService}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmModal}
        title="Delete Product & Service?"
        messages={[`Please confirm you want delete ${productService.name}?`]}
        onOK={() => deleteProductService(productService)}
        onCancel={handleCloseConfirmModal}
      />
    </div>
  )
}

export default ProductServices;
