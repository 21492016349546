import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import './style.css';

class YesNoDropdown extends React.Component {
  handleOnChange = (value) => {
    if (this.props.onSelect) {
      this.props.onSelect({target: {name: this.props.name || '', value: value}});
    }
  };

  render() {
    return (
      <DropdownButton
        className="yes-no-dropdown"
        name={this.props.name || ''}
        title={this.props.value === true ? 'Yes' : 'No'}
      >
        <Dropdown.Item
          as="button"
          key={0}
          onClick={() => this.handleOnChange(true)}
          active={this.props.value === true}
        >
          Yes
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          key={1}
          onClick={() => this.handleOnChange(false)}
          active={this.props.value === false}
        >
          No
        </Dropdown.Item>
      </DropdownButton>
    )
  }
}

YesNoDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default YesNoDropdown;