import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

import './style.css';

class MyTimePicker extends React.Component {
  handleOnChange = (value) => {
    if (this.props.onSelect) {
      if (value) {
        const h = value.get('hours');
        const m = value.get('minutes');
        value = value.toDate();
        if (this.props.displayUTC) {
          value.setUTCHours(h, m);
        }
      }
      this.props.onSelect({target: {name: this.props.name || '', value: value}});
    }
  };

  formatLocaleTime = (value) => {
    if (value) {
      const date = new Date(value);
      const h = this.props.displayUTC ? date.getUTCHours() : date.getHours();
      const m = this.props.displayUTC ? date.getUTCMinutes() : date.getMinutes();

      date.setHours(h, m);
      return moment(date);
    }
  };

  render() {
    return (
      <TimePicker
        showSecond={false}
        allowEmpty={false}
        value={this.props.value ? this.formatLocaleTime(this.props.value) : null}
        onChange={this.handleOnChange}
        format={'h:mm a'}
        use12Hours
        inputReadOnly
        disabled={this.props.disabled}
      />
    )
  }
}

MyTimePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onSelect: PropTypes.func,
  displayUTC: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MyTimePicker;