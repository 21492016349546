import React, {useState, useEffect} from 'react';
import {sortBy, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {Link, useLocation} from "react-router-dom";
import {
  fnGetQuotes,
  fnAddQuote,
  fnApproveQuote,
  fnEditQuote,
  fnGetClients,
  fnGetLeads,
  fnGetProjects,
  fnGetProductServices,
} from '../../api';
import {
  logError,
  displayDate,
  displayMoney,
  nowLocaleDateString,
  roundMoney,
  responseDataExists,
  displayClientOption,
  displayLeadOption,
  displayProductServiceOption,
  displayProjectOption,
} from '../../helpers';
import DatePicker from '../../components/DatePicker';
import SearchDropdown from '../../components/SearchDropdown';
import ConfirmationModal from '../../components/ConfirmationModal';
import Filters from '../../components/Filters';

import './style.css';

export const defaultQuote = () => {
  return {
    quote_id: null,
    client_id: null,
    lead_id: null,
    quote_num: '',
    date: nowLocaleDateString(),
    date_valid: nowLocaleDateString(),
    for_details: {
      company: '',
      name: '',
      phone: '',
      address: '',
      town_province: '',
      postal_code: '',
    },
    at_details: {
      name: '',
      address: '',
      town_province: '',
      postal_code: '',
    },
    items: [],
    descriptions: [],
    prices: [],
    quantities: [],
    subtotal: 0.0,
    tax: 0.0,
    notes: '',
  }
};

export function Quotes() {
  const [quotes, setQuotes] = useState([]);
  const [quote, setQuote] = useState(defaultQuote());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [clientOptions, setClientOptions] = useState([]);
  const [leadOptions, setLeadOptions] = useState([]);
  const [productServiceOptions, setProductServiceOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState({original: [], filtered: []});
  const [confirmModalDetails, setConfirmModalDetails] = useState({show: false, projectID: null, missing: false});
  const location = useLocation();

  function calculateSubtotalTax(quote) {
    const {prices, quantities} = quote;

    let subtotal = 0;
    prices.forEach((p, i) => {
      subtotal += p * quantities[i];
    });

    let tax = subtotal * 0.13;

    // round to 2 decimal places
    subtotal = roundMoney(subtotal);
    tax = roundMoney(tax);

    return {subtotal, tax};
  }

  function handleOpenModal(quote) {
    if (!quote) {
      quote = defaultQuote();
    } else {
      quote = cloneDeep(quote);
    }

    setQuote(quote);
    setModalDetails({...modalDetails, show: true, isEdit: quote.quote_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleOpenConfirmModal(quote) {
    if (quote) {
      setQuote(quote);
      setProjectOptions({
        ...projectOptions,
        filtered: projectOptions.original.filter((o) =>
          (o.client_id && o.client_id === quote.client_id) || (o.lead_id && o.lead_id === quote.lead_id)
        ),
      });
      setConfirmModalDetails({...confirmModalDetails, show: true});
    }
  }

  function handleCloseConfirmModal() {
    setConfirmModalDetails({...confirmModalDetails, show: false, projectID: null, missing: false});
  }

  function handleOkConfirmationModal() {
    if (confirmModalDetails.projectID) {
      approveQuote(confirmModalDetails.projectID);
    } else {
      setConfirmModalDetails({...confirmModalDetails, missing: true});
    }
  }

  function handleConfirmationModalSelectProject(e) {
    const project = e.target.value;
    if (project && project.project_id) {
      setConfirmModalDetails({...confirmModalDetails, projectID: project.project_id});
    }
  }

  function handleConfirmationModalSelectProjectOnClick() {
    if (confirmModalDetails.missing === true) {
      setConfirmModalDetails({...confirmModalDetails, missing: false});
    }

  }

  function handleQuoteFieldUpdate(e) {
    const updatedQuote = {...quote};
    let name = e.target.name;
    let value = e.target.value;

    // parse for field names with subfields (ie object) or elements (ie arrays)
    // and extract the key or index
    let subKey = null;
    if (name.startsWith('for_details') || name.startsWith('at_details')) {
      const names = name.split('.');
      name = names[0];
      if (names.length > 1) {
        subKey = names[1];
      }
    } else if (name.startsWith('items') || name.startsWith('descriptions') ||
      name.startsWith('prices') || name.startsWith('quantities')) {
      const names = name.split('.');
      name = names[0];
      if (names.length > 1) {
        subKey = parseInt(names[1], 10);
      }
    }

    switch (name) {
      case 'client_id':
      case 'lead_id':
        if (value) {
          const forDetails = updatedQuote.for_details;
          // default fill fields with client/lead data
          if (value.company && !forDetails.company) {
            forDetails.company = value.company;
          }
          if (value.name && !forDetails.name) {
            forDetails.name = value.name;
          }
          if (value.phone && !forDetails.phone) {
            forDetails.phone = value.phone;
          }
          if (value.address && !forDetails.address) {
            forDetails.address = value.address;
          }

          // reassign value for updating
          value = value[name];
        }
        break;
      case 'date':
      case 'date_valid':
        if (value) {
          value = value.toJSON();
        }
        break;
      case 'for_details':
      case 'at_details':
        // update object fields
        const details = updatedQuote[name];
        if (subKey) {
          details[subKey] = value;
        }

        // reassign value for updating
        value = details;
        break;
      case 'items':
      case 'descriptions':
      case 'prices':
      case 'quantities':
        // update array elements
        const list = updatedQuote[name];
        if (typeof subKey === 'number') {
          // make sure quantity values are integers
          if (name === 'quantities') {
            switch (typeof value) {
              case 'number':
                value = Math.floor(value);
                break;
              case 'string':
                value = parseInt(value, 10);
                break;
              default:
                console.log(`setting quantity with ${typeof value}`)
            }
          }

          list[subKey] = value;
        }

        // reassign value for updating
        value = list;

        // recalculate subtotal and tax
        if (['prices', 'quantities'].includes(name)) {
          const updatedSubtotalTax = calculateSubtotalTax(updatedQuote);
          updatedQuote.subtotal = updatedSubtotalTax.subtotal;
          updatedQuote.tax = updatedSubtotalTax.tax;
        }
        break;
      case 'subtotal':
      case 'tax':
        switch (typeof value) {
          case 'string':
            value = parseFloat(value);
          // eslint-disable-next-line
          case 'number':
            value = roundMoney(value);
            break;
          default:
            console.log(`setting subtotal/tax with ${typeof value}`)
        }
        break;
      default:
    }

    updatedQuote[name] = value;
    console.log(updatedQuote);
    setQuote(updatedQuote);
  }

  function handleAddItem(e) {
    const updatedQuote = {...quote};
    const {items, descriptions, prices, quantities} = updatedQuote;
    const value = e.target.value;

    if (value) {
      if (value.name || value.price !== null) {
        if (value.name) {
          items.unshift(value.name);
        } else {
          items.unshift('');
        }

        if (value.description) {
          descriptions.unshift(value.description);
        } else {
          descriptions.unshift('');
        }

        if (value.price) {
          prices.unshift(value.price);
        } else {
          prices.unshift(0.0);
        }

        quantities.unshift(1);
      }

      // recalculate subtotal and tax
      const updatedSubtotalTax = calculateSubtotalTax(updatedQuote);
      updatedQuote.subtotal = updatedSubtotalTax.subtotal;
      updatedQuote.tax = updatedSubtotalTax.tax;

      setQuote(updatedQuote);
    }
  }

  function handleDeleteItem(i) {
    const updatedQuote = {...quote};
    const {items, descriptions, prices, quantities} = updatedQuote;

    if (typeof i === 'number') {
      items.splice(i, 1);
      descriptions.splice(i, 1);
      prices.splice(i, 1);
      quantities.splice(i, 1);

      const updatedSubtotalTax = calculateSubtotalTax(updatedQuote);
      updatedQuote.subtotal = updatedSubtotalTax.subtotal;
      updatedQuote.tax = updatedSubtotalTax.tax;

      setQuote(updatedQuote);
    }
  }

  function getQuotes(filters = {clientID: null}) {
    const data = {client_id: filters.clientID};

    fnGetQuotes(data).then((response) => {
      console.log('quotes:\n', response.data);
      if (responseDataExists(response)) {
        setQuotes(response.data.data || []);
      }
    }).catch(logError)
  }

  function addQuote() {
    if (quote) {
      fnAddQuote(quote).then((response) => {
        console.log('add quote:\n', response.data);
        if (response.data && response.data.data) {
          setQuotes([response.data.data, ...quotes]);
        }
        handleCloseModal();
      }).catch(logError)
    } else {
      handleCloseModal();
    }
  }

  function approveQuote(projectID = null) {
    if (quote && projectID) {
      fnApproveQuote(quote.quote_id, {project_id: projectID}).then(() => {
        console.log('approved quote:\n', quote);
        setQuotes(quotes.filter((q) => q.quote_id !== quote.quote_id));
        handleCloseConfirmModal();
      }).catch(logError)
    } else {
      handleCloseConfirmModal();
    }
  }

  function editQuote() {
    if (quote) {
      fnEditQuote(quote.quote_id, quote).then((response) => {
        console.log('edit quote:\n', response.data);
        if (response.data && response.data.data) {
          setQuotes(quotes.map((q) => q.quote_id === quote.quote_id ? response.data.data : q));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function getClientOptions(search = '') {
    fnGetClients({search: search}).then((response) => {
      console.log('client options:\n', response.data);
      if (responseDataExists(response)) {
        setClientOptions(
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayClientOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getLeadOptions(search = '') {
    fnGetLeads({search: search}).then((response) => {
      console.log('lead options:\n', response.data);
      if (responseDataExists(response)) {
        setLeadOptions(
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayLeadOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getProductServiceOptions(search = '') {
    fnGetProductServices({search: search}).then((response) => {
      console.log('product service options:\n', response.data);
      if (responseDataExists(response)) {
        setProductServiceOptions(
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayProductServiceOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getProjectOptions(search = '') {
    fnGetProjects({search: search}).then((response) => {
      console.log('project options:\n', response.data);
      if (responseDataExists(response)) {
        const original = sortBy(
          (response.data.data || []).map((o) => {
            o.displayOption = displayProjectOption(o);
            return o;
          }),
          (o) => o.displayOption
        );
        setProjectOptions({original, filtered: [...original]});
      }
    }).catch(logError)
  }

  useEffect(() => {
    if (!(location && location.state && location.state.clientID)) {
      getQuotes();
    }
    getClientOptions();
    getLeadOptions();
    getProductServiceOptions();
    getProjectOptions();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>Quotes</h1>
        <div className="right">
          <Filters
            hideSearch
            hideDateRange
            showClient
            clientOptions={clientOptions}
            onChangeClient={getQuotes}
            initialClientID={(location && location.state && location.state.clientID) || null}
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Date</th>
            <th>Date Valid</th>
            <th>Company</th>
            <th>At Address</th>
            <th>At Town/Province</th>
            <th>Total</th>
            <th>Statement</th>
            <th>Approve</th>
          </tr>
          </thead>
          <tbody>
          {quotes.map((quote, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(quote)}>{displayDate(quote.date)}</td>
              <td onClick={() => handleOpenModal(quote)}>{displayDate(quote.date_valid)}</td>
              <td onClick={() => handleOpenModal(quote)}>{quote.for_details.name}</td>
              <td onClick={() => handleOpenModal(quote)}>{quote.at_details.address}</td>
              <td onClick={() => handleOpenModal(quote)}>{quote.at_details.town_province}</td>
              <td onClick={() => handleOpenModal(quote)}>{displayMoney(quote.subtotal + quote.tax)}</td>
              <td>
                <Link
                  to={`/quotes/${quote.quote_id}/view`}
                  state={{data: quote}}
                >
                  View
                </Link>
              </td>
              <td>
                <button onClick={() => handleOpenConfirmModal(quote)}>Confirm</button>
              </td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Quote</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!modalDetails.isEdit ?
            <>
              <div>
                <label htmlFor="client_id">Client:</label>
                <SearchDropdown
                  name="client_id"
                  value={quote.client_id}
                  onSelect={handleQuoteFieldUpdate}
                  options={clientOptions}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.client_id}
                  showClear={true}
                  disabled={quote.lead_id !== null}
                />
              </div>
              <div>
                <label htmlFor="lead_id">Lead:</label>
                <SearchDropdown
                  name="lead_id"
                  value={quote.lead_id}
                  onSelect={handleQuoteFieldUpdate}
                  options={leadOptions}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.lead_id}
                  showClear={true}
                  disabled={quote.client_id !== null}
                />
              </div>
            </>
            :
            null
          }
          <div>
            <label htmlFor="date">Date:</label>
            <DatePicker
              name="date"
              value={quote.date}
              onSelect={handleQuoteFieldUpdate}
            />
          </div>
          <div>
            <label htmlFor="date_valid">Date Valid:</label>
            <DatePicker
              name="date_valid"
              value={quote.date_valid}
              onSelect={handleQuoteFieldUpdate}
            />
          </div>
          <div className="sub-questions">
            <h5>Prepared For:</h5>
            <div>
              <label htmlFor="for_details.company">Company:</label>
              <input
                type="text"
                name="for_details.company"
                onChange={handleQuoteFieldUpdate}
                value={quote.for_details.company}
              />
            </div>
            <div>
              <label htmlFor="for_details.name">Name:</label>
              <input
                type="text"
                name="for_details.name"
                onChange={handleQuoteFieldUpdate}
                value={quote.for_details.name}
              />
            </div>
            <div>
              <label htmlFor="for_details.phone">Phone:</label>
              <input
                type="text"
                name="for_details.phone"
                onChange={handleQuoteFieldUpdate}
                value={quote.for_details.phone}
              />
            </div>
            <div>
              <label htmlFor="for_details.address">Address:</label>
              <input
                type="text"
                name="for_details.address"
                onChange={handleQuoteFieldUpdate}
                value={quote.for_details.address}
              />
            </div>
            <div>
              <label htmlFor="for_details.town_province">Town/Province:</label>
              <input
                type="text"
                name="for_details.town_province"
                onChange={handleQuoteFieldUpdate}
                value={quote.for_details.town_province}
              />
            </div>
            <div>
              <label htmlFor="for_details.postal_code">Postal Code:</label>
              <input
                type="text"
                name="for_details.postal_code"
                onChange={handleQuoteFieldUpdate}
                value={quote.for_details.postal_code}
              />
            </div>
          </div>
          <div className="sub-questions">
            <h5>Service Address:</h5>
            <div>
              <label htmlFor="at_details.name">Name:</label>
              <input
                type="text"
                name="at_details.name"
                onChange={handleQuoteFieldUpdate}
                value={quote.at_details.name}
              />
            </div>
            <div>
              <label htmlFor="at_details.address">Address:</label>
              <input
                type="text"
                name="at_details.address"
                onChange={handleQuoteFieldUpdate}
                value={quote.at_details.address}
              />
            </div>
            <div>
              <label htmlFor="at_details.town_province">Town/Province:</label>
              <input
                type="text"
                name="at_details.town_province"
                onChange={handleQuoteFieldUpdate}
                value={quote.at_details.town_province}
              />
            </div>
            <div>
              <label htmlFor="at_details.postal_code">Postal Code:</label>
              <input
                type="text"
                name="at_details.postal_code"
                onChange={handleQuoteFieldUpdate}
                value={quote.at_details.postal_code}
              />
            </div>
          </div>
          <div className="sub-questions">
            <h5>Items:</h5>
            <div>
              <SearchDropdown
                value={null}
                onSelect={handleAddItem}
                options={productServiceOptions}
                fnDisplayOption={(option) => option.displayOption}
                placeholder="Product/Service"
                dismissSelected={true}
              />
            </div>
            <table>
              <thead>
              {quote.items.length > 0 ?
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Delete</th>
                </tr>
                :
                null
              }
              </thead>
              <tbody>
              {quote.items.map((item, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type="text"
                      name={`items.${i}`}
                      onChange={handleQuoteFieldUpdate}
                      value={item}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name={`descriptions.${i}`}
                      onChange={handleQuoteFieldUpdate}
                      value={quote.descriptions[i]}
                    />
                  </td>
                  <td>
                    <input
                      className="input-number"
                      type="number"
                      name={`prices.${i}`}
                      onChange={handleQuoteFieldUpdate}
                      value={quote.prices[i]}
                    />
                  </td>
                  <td>
                    <input
                      className="input-number"
                      type="number"
                      name={`quantities.${i}`}
                      onChange={handleQuoteFieldUpdate}
                      value={quote.quantities[i]}
                    />
                  </td>
                  <td>
                    <span
                      className="btn-clear"
                      onClick={() => handleDeleteItem(i)}
                    >
                      ❌
                    </span>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div>
            <label htmlFor="subtotal">Subtotal:</label>
            <input
              type="number"
              name="subtotal"
              onChange={handleQuoteFieldUpdate}
              value={quote.subtotal}
            />
          </div>
          <div>
            <label htmlFor="tax">Taxes:</label>
            <input
              type="number"
              name="tax"
              onChange={handleQuoteFieldUpdate}
              value={quote.tax}
            />
          </div>
          <div>
            <label htmlFor="total">Total:</label>
            <input
              type="number"
              name="total"
              value={roundMoney(quote.subtotal + quote.tax)}
              disabled
            />
          </div>
          <div>
            <label htmlFor="notes">Notes:</label>
            <textarea
              name="notes"
              onChange={handleQuoteFieldUpdate}
              value={quote.notes}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editQuote : addQuote}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={confirmModalDetails.show}
        title="Approve Quote?"
        messages={[
          'Please confirm you want to approve quote for:',
          `Company: ${quote.for_details.company}`,
          `Date: ${displayDate(quote.date)}`,
          `Amount: ${displayMoney(quote.subtotal + quote.tax)}`,
        ]}
        onOK={handleOkConfirmationModal}
        onCancel={handleCloseConfirmModal}
      >
        <div>
          <label htmlFor="project_id">Project:</label>
          <SearchDropdown
            name="project_id"
            value={confirmModalDetails.projectID}
            onSelect={handleConfirmationModalSelectProject}
            options={projectOptions.filtered}
            fnDisplayOption={(option) => option.displayOption}
            fnValueMatchOption={(value, option) => value === option.project_id}
            className={confirmModalDetails.missing ? 'missing' : ''}
            onClick={handleConfirmationModalSelectProjectOnClick}
          />
        </div>
      </ConfirmationModal>
    </div>
  )
}

export default Quotes;
