export const loadStateWithKey = (key) => {
  try {
    let serializedState;
    serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return '';
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return '';
  }
};

export const saveStateWithKey = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    // pass
  }
};