import React, {useState, useEffect} from 'react';
import {debounce} from 'lodash';
import {useParams, useLocation, useLoaderData, Link} from 'react-router-dom';
import {fnGetClientLogs, fnGetLeadLogs} from '../../api';
import {
  logError,
  displayDate,
  displayTime,
  responseDataExists,
  routeParamClientID,
  routeParamLeadID,
} from '../../helpers';
import Filters from '../../components/Filters';

import './style.css';

export const LogHistoryTypeClient = 'CLIENT_LOG';
export const LogHistoryTypeLead = 'LEAD_LOG';

export function LogHistory() {
  const params = useParams();
  const location = useLocation();
  const logHistoryType = useLoaderData();

  const [logs, setLogs] = useState([
    // {
    //   log_id: 0,
    //   log_type: '',
    //   details: '',
    //   associations: {},
    //   ts_created: '2023-08-11T20:48:20.698Z',
    // }
  ]);

  function generateLog(log) {
    switch (log.log_type) {
      case 'Estimate Abandoned':
      case 'Estimate Won':
        return <>{log.log_type}</>;
      case 'Invoice Created':
        return (
          <>
            {log.log_type}.&nbsp;
            <Link to={`/invoices/${log.associations.invoice_id}/view`}>
              {log.associations.invoice_num}
            </Link>
          </>
        );
      case 'Project Completed':
      case 'Project Start':
        return <>{log.log_type}</>;
      case 'Quote Created':
        return (
          <>
            {log.log_type}.&nbsp;
            <Link to={`/quotes/${log.associations.quote_id}/view`}>
              {log.associations.quote_num}
            </Link>
          </>
        );
      default:
        console.log(`unsupported log type "${log.log_type}"`);
        return
    }
  }

  function getLogs(filters = {search: ''}) {
    const data = {search: filters.search};
    if (filters.fromTs && filters.toTs) {
      data.filter_from_ts = filters.fromTs;
      data.filter_to_ts = filters.toTs;
    }

    let request = null;
    switch (logHistoryType) {
      case LogHistoryTypeClient:
        request = fnGetClientLogs(params[routeParamClientID], data);
        break;
      case LogHistoryTypeLead:
        request = fnGetLeadLogs(params[routeParamLeadID], data);
        break;
      default:
        console.log(`invalid log history type: "${logHistoryType}"`);
    }

    if (request) {
      request.then((response) => {
        console.log(`${logHistoryType}s:\n`, response.data);
        if (responseDataExists(response)) {
          setLogs(response.data.data || []);
        }
      }).catch(logError)
    }
  }

  const delayGetLogs = debounce((...args) => getLogs(...args), 1000);

  useEffect(() => {
    getLogs();
  }, []);

  return (
    <div>
      <div className="page-header">
        <div className="left">
          {location && location.state && location.state.back ?
            <Link
              className="back-link"
              to={location.state.back}
            >
              {location.state.backName ? location.state.backName : 'back'}
            </Link>
            :
            null
          }
          <h1>
            Project History
            {location && location.state && location.state.client !== '' ? ` - ${location.state.client}` : ''}
          </h1>
        </div>
        <div className="right">
          <Filters
            onChangeSearch={delayGetLogs}
            onChangeDateRange={getLogs}
          />
        </div>
      </div>

      <div className="page-body divider">
        <table className="page-table">
          <tbody>
          {logs.map((log, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td>{log.log_id}) {generateLog(log)}</td>
              <td>{`${displayDate(log.ts_created)} ${displayTime(log.ts_created, true)}`}</td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default LogHistory;
