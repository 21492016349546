import React, {useState, useEffect} from 'react';
import {sortBy, cloneDeep} from "lodash";
import Modal from 'react-bootstrap/Modal';
import {Link, useLocation} from "react-router-dom";
import {
  fnGetClients,
  fnGetInvoices,
  fnAddInvoice,
  fnEditInvoice,
  fnGetLeads,
  fnGetProductServices,
  fnGetProjects,
} from '../../api';
import {
  logError,
  displayDate,
  displayMoney,
  roundMoney,
  responseDataExists,
  displayClientOption,
  displayLeadOption,
  displayProductServiceOption,
  displayProjectOption,
  soKeyInvoiceStatus,
} from '../../helpers';
import DatePicker from '../../components/DatePicker';
import SearchDropdown from '../../components/SearchDropdown';
import Filters from '../../components/Filters';
import StaticOptionDropdown from '../../components/StaticOptionDropdown';
import {defaultQuote} from '../Quotes';

import './style.css';

export const defaultInvoice = () => {
  return {
    ...defaultQuote(),
    invoice_id: null,
    ts_approved: null,
    invoice_status: null,
    project_id: null,
    invoice_num: '',
  }
};

export function Invoices() {
  const [invoices, setInvoices] = useState([]);
  const [invoice, setInvoice] = useState(defaultQuote());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [clientOptions, setClientOptions] = useState({original: [], filtered: []});
  const [leadOptions, setLeadOptions] = useState([]);
  const [productServiceOptions, setProductServiceOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState({original: [], filtered: []});
  const location = useLocation();

  function calculateSubtotalTax(invoice) {
    const {prices, quantities} = invoice;

    let subtotal = 0;
    prices.forEach((p, i) => {
      subtotal += p * quantities[i];
    });

    let tax = subtotal * 0.13;

    // round to 2 decimal places
    subtotal = roundMoney(subtotal);
    tax = roundMoney(tax);

    return {subtotal, tax};
  }

  function handleOpenModal(invoice) {
    if (!invoice) {
      invoice = defaultInvoice();
    } else {
      invoice = cloneDeep(invoice);
    }

    setInvoice(invoice);
    setModalDetails({...modalDetails, show: true, isEdit: invoice.invoice_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleInvoiceFieldUpdate(e) {
    const updatedInvoice = {...invoice};
    let name = e.target.name;
    let value = e.target.value;

    // parse for field names with subfields (ie object) or elements (ie arrays)
    // and extract the key or index
    let subKey = null;
    if (name.startsWith('for_details') || name.startsWith('at_details')) {
      const names = name.split('.');
      name = names[0];
      if (names.length > 1) {
        subKey = names[1];
      }
    } else if (name.startsWith('items') || name.startsWith('descriptions') ||
      name.startsWith('prices') || name.startsWith('quantities')) {
      const names = name.split('.');
      name = names[0];
      if (names.length > 1) {
        subKey = parseInt(names[1], 10);
      }
    }

    switch (name) {
      case 'client_id':
      case 'lead_id':
        if (value) {
          const forDetails = updatedInvoice.for_details;
          // default fill fields with client/lead data
          if (value.company && !forDetails.company) {
            forDetails.company = value.company;
          }
          if (value.name && !forDetails.name) {
            forDetails.name = value.name;
          }
          if (value.phone && !forDetails.phone) {
            forDetails.phone = value.phone;
          }
          if (value.address && !forDetails.address) {
            forDetails.address = value.address;
          }

          // reassign value for updating
          value = value[name];

          // filter project options
          console.log([...projectOptions.original].filter((o) => o[name] === value));
          setProjectOptions({
            ...projectOptions,
            filtered: [...projectOptions.original].filter((o) => o[name] === value)
          })
        } else {
          // reset project options
          setProjectOptions({...projectOptions, filtered: [...projectOptions.original]});
        }
        break;
      case 'project_id':
        if (value) {
          const forDetails = updatedInvoice.for_details;
          // default fill fields with project data
          if (value.contact_name && !forDetails.name) {
            forDetails.name = value.contact_name;
          }
          if (value.contact_phone && !forDetails.phone) {
            forDetails.phone = value.contact_phone;
          }
          if (value.address && !forDetails.address) {
            forDetails.address = value.address;
          }

          // default client/lead id
          updatedInvoice.client_id = value.client_id;
          updatedInvoice.lead_id = value.lead_id;

          // reassign value for updating
          value = value[name];
        }

        break;
      case 'date':
      case 'date_valid':
        if (value) {
          value = value.toJSON();
        }
        break;
      case 'for_details':
      case 'at_details':
        // update object fields
        const details = updatedInvoice[name];
        if (subKey) {
          details[subKey] = value;
        }

        // reassign value for updating
        value = details;
        break;
      case 'items':
      case 'descriptions':
      case 'prices':
      case 'quantities':
        // update array elements
        const list = updatedInvoice[name];
        if (typeof subKey === 'number') {
          // make sure quantity values are integers
          if (name === 'quantities') {
            switch (typeof value) {
              case 'number':
                value = Math.floor(value);
                break;
              case 'string':
                value = parseInt(value, 10);
                break;
              default:
                console.log(`setting quantity with ${typeof value}`)
            }
          }

          list[subKey] = value;
        }

        // reassign value for updating
        value = list;

        // recalculate subtotal and tax
        if (['prices', 'quantities'].includes(name)) {
          const updatedSubtotalTax = calculateSubtotalTax(updatedInvoice);
          updatedInvoice.subtotal = updatedSubtotalTax.subtotal;
          updatedInvoice.tax = updatedSubtotalTax.tax;
        }
        break;
      case 'subtotal':
      case 'tax':
        switch (typeof value) {
          case 'string':
            value = parseFloat(value);
          // eslint-disable-next-line
          case 'number':
            value = roundMoney(value);
            break;
          default:
            console.log(`setting subtotal/tax with ${typeof value}`)
        }
        break;
      default:
    }

    updatedInvoice[name] = value;
    setInvoice(updatedInvoice);
  }

  function handleAddItem(e) {
    const updatedInvoice = {...invoice};
    const {items, descriptions, prices, quantities} = updatedInvoice;
    const value = e.target.value;

    if (value) {
      if (value.name || value.price !== null) {
        if (value.name) {
          items.unshift(value.name);
        } else {
          items.unshift('');
        }

        if (value.description) {
          descriptions.unshift(value.description);
        } else {
          descriptions.unshift('');
        }

        if (value.price) {
          prices.unshift(value.price);
        } else {
          prices.unshift(0.0);
        }

        quantities.unshift(1);
      }

      // recalculate subtotal and tax
      const updatedSubtotalTax = calculateSubtotalTax(updatedInvoice);
      updatedInvoice.subtotal = updatedSubtotalTax.subtotal;
      updatedInvoice.tax = updatedSubtotalTax.tax;

      setInvoice(updatedInvoice);
    }
  }

  function handleDeleteItem(i) {
    const updatedInvoice = {...invoice};
    const {items, descriptions, prices, quantities} = updatedInvoice;

    if (typeof i === 'number') {
      items.splice(i, 1);
      descriptions.splice(i, 1);
      prices.splice(i, 1);
      quantities.splice(i, 1);

      const updatedSubtotalTax = calculateSubtotalTax(updatedInvoice);
      updatedInvoice.subtotal = updatedSubtotalTax.subtotal;
      updatedInvoice.tax = updatedSubtotalTax.tax;

      setInvoice(updatedInvoice);
    }
  }

  function getInvoices(filters = {clientID: null}) {
    const data = {client_id: filters.clientID};

    fnGetInvoices(data).then((response) => {
      console.log('invoices:\n', response.data);
      if (responseDataExists(response)) {
        setInvoices(response.data.data || []);
      }
    }).catch(logError)
  }

  function addInvoice() {
    if (invoice) {
      fnAddInvoice(invoice).then((response) => {
        console.log('add invoice:\n', response.data);
        if (response.data && response.data.data) {
          setInvoices([response.data.data, ...invoices]);
        }
        handleCloseModal();
      }).catch(logError)
    } else {
      handleCloseModal();
    }
  }

  function editInvoice() {
    if (invoice) {
      fnEditInvoice(invoice.invoice_id, invoice).then((response) => {
        console.log('edit invoice:\n', response.data);
        if (response.data && response.data.data) {
          setInvoices(invoices.map((i) => i.invoice_id === invoice.invoice_id ? response.data.data : i));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function getClientOptions(search = '', clientIDs = {}) {
    fnGetClients({search: search}).then((response) => {
      console.log('client options:\n', response.data);
      if (responseDataExists(response)) {
        const original =
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayClientOption(o);
              return o;
            }),
            (o) => o.displayOption
          );
        setClientOptions({original, filtered: original.filter((o) => clientIDs[o.client_id])});
      }
    }).catch(logError)
  }

  function getLeadOptions(search = '', leadIDs = {}) {
    fnGetLeads({search: search}).then((response) => {
      console.log('lead options:\n', response.data);
      if (responseDataExists(response)) {
        setLeadOptions(
          sortBy(
            (response.data.data || []).filter((o) => leadIDs[o.lead_id]).map((o) => {
              o.displayOption = displayLeadOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getProductServiceOptions(search = '') {
    fnGetProductServices({search: search}).then((response) => {
      console.log('product service options:\n', response.data);
      if (responseDataExists(response)) {
        setProductServiceOptions(
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayProductServiceOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getProjectOptions(search = '') {
    fnGetProjects({search: search}).then((response) => {
      console.log('project options:\n', response.data);
      if (responseDataExists(response)) {
        const clientIDs = {};
        const leadIDs = {};
        const original = sortBy(
          (response.data.data || []).map((o) => {
            // generate mapping for clients and leads
            if (o.client_id) {
              clientIDs[o.client_id] = true;
            }
            if (o.lead_id) {
              leadIDs[o.lead_id] = true;
            }

            o.displayOption = displayProjectOption(o);
            return o;
          }),
          (o) => o.displayOption
        );
        setProjectOptions({original, filtered: [...original]});

        // get client/lead options
        getClientOptions('', clientIDs);
        getLeadOptions('', leadIDs);
      }
    }).catch(logError)
  }

  useEffect(() => {
    if (!(location && location.state && location.state.clientID)) {
      getInvoices();
    }
    getProductServiceOptions();
    getProjectOptions();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>Invoices</h1>
        <div className="right">
          <Filters
            hideSearch
            hideDateRange
            showClient
            clientOptions={clientOptions.original}
            onChangeClient={getInvoices}
            initialClientID={(location && location.state && location.state.clientID) || null}
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Invoice #</th>
            <th>Date</th>
            <th>Due Date</th>
            <th>Company</th>
            <th>At Address</th>
            <th>At Town/Province</th>
            <th>Total</th>
            <th>Status</th>
            <th>Statement</th>
          </tr>
          </thead>
          <tbody>
          {invoices.map((invoice, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(invoice)}>{invoice.invoice_num}</td>
              <td onClick={() => handleOpenModal(invoice)}>{displayDate(invoice.date)}</td>
              <td onClick={() => handleOpenModal(invoice)}>{displayDate(invoice.date_valid)}</td>
              <td onClick={() => handleOpenModal(invoice)}>{invoice.for_details.name}</td>
              <td onClick={() => handleOpenModal(invoice)}>{invoice.at_details.address}</td>
              <td onClick={() => handleOpenModal(invoice)}>{invoice.at_details.town_province}</td>
              <td onClick={() => handleOpenModal(invoice)}>
                {displayMoney(invoice.subtotal + invoice.tax)}
              </td>
              <td onClick={() => handleOpenModal(invoice)}>{invoice.invoice_status}</td>
              <td>
                <Link
                  to={`/invoices/${invoice.invoice_id}/view`}
                  state={{data: invoice}}
                >
                  View
                </Link>
              </td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Invoice</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!modalDetails.isEdit ?
            <>
              <div>
                <label htmlFor="client_id">Client:</label>
                <SearchDropdown
                  name="client_id"
                  value={invoice.client_id}
                  onSelect={handleInvoiceFieldUpdate}
                  options={clientOptions.filtered}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.client_id}
                  showClear
                  disabled={invoice.lead_id !== null || invoice.project_id !== null}
                  resetValue
                />
              </div>
              <div>
                <label htmlFor="lead_id">Lead:</label>
                <SearchDropdown
                  name="lead_id"
                  value={invoice.lead_id}
                  onSelect={handleInvoiceFieldUpdate}
                  options={leadOptions}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.lead_id}
                  showClear
                  disabled={invoice.client_id !== null || invoice.project_id !== null}
                  resetValue
                />
              </div>
              <div>
                <label htmlFor="project_id">Project:</label>
                <SearchDropdown
                  name="project_id"
                  value={invoice.project_id}
                  onSelect={handleInvoiceFieldUpdate}
                  options={projectOptions.filtered}
                  fnDisplayOption={(option) => option.displayOption}
                  fnValueMatchOption={(value, option) => value === option.project_id}
                  showClear
                />
              </div>
            </>
            :
            null
          }
          <div>
            <label htmlFor="date">Date:</label>
            <DatePicker
              name="date"
              value={invoice.date}
              onSelect={handleInvoiceFieldUpdate}
            />
          </div>
          <div>
            <label htmlFor="date_valid">Date Valid:</label>
            <DatePicker
              name="date_valid"
              value={invoice.date_valid}
              onSelect={handleInvoiceFieldUpdate}
            />
          </div>
          <div className="sub-questions">
            <h5>Prepared For:</h5>
            <div>
              <label htmlFor="for_details.company">Company:</label>
              <input
                type="text"
                name="for_details.company"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.company}
              />
            </div>
            <div>
              <label htmlFor="for_details.name">Name:</label>
              <input
                type="text"
                name="for_details.name"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.name}
              />
            </div>
            <div>
              <label htmlFor="for_details.phone">Phone:</label>
              <input
                type="text"
                name="for_details.phone"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.phone}
              />
            </div>
            <div>
              <label htmlFor="for_details.address">Address:</label>
              <input
                type="text"
                name="for_details.address"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.address}
              />
            </div>
            <div>
              <label htmlFor="for_details.town_province">Town/Province:</label>
              <input
                type="text"
                name="for_details.town_province"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.town_province}
              />
            </div>
            <div>
              <label htmlFor="for_details.postal_code">Postal Code:</label>
              <input
                type="text"
                name="for_details.postal_code"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.for_details.postal_code}
              />
            </div>
          </div>
          <div className="sub-questions">
            <h5>Service Address:</h5>
            <div>
              <label htmlFor="at_details.name">Name:</label>
              <input
                type="text"
                name="at_details.name"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.at_details.name}
              />
            </div>
            <div>
              <label htmlFor="at_details.address">Address:</label>
              <input
                type="text"
                name="at_details.address"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.at_details.address}
              />
            </div>
            <div>
              <label htmlFor="at_details.town_province">Town/Province:</label>
              <input
                type="text"
                name="at_details.town_province"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.at_details.town_province}
              />
            </div>
            <div>
              <label htmlFor="at_details.postal_code">Postal Code:</label>
              <input
                type="text"
                name="at_details.postal_code"
                onChange={handleInvoiceFieldUpdate}
                value={invoice.at_details.postal_code}
              />
            </div>
          </div>
          <div className="sub-questions">
            <h5>Items:</h5>
            <div>
              <SearchDropdown
                value={null}
                onSelect={handleAddItem}
                options={productServiceOptions}
                fnDisplayOption={(option) => option.displayOption}
                placeholder="Product/Service"
                dismissSelected={true}
              />
            </div>
            <table>
              <thead>
              {invoice.items.length > 0 ?
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Delete</th>
                </tr>
                :
                null
              }
              </thead>
              <tbody>
              {invoice.items.map((item, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type="text"
                      name={`items.${i}`}
                      onChange={handleInvoiceFieldUpdate}
                      value={item}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name={`descriptions.${i}`}
                      onChange={handleInvoiceFieldUpdate}
                      value={invoice.descriptions[i]}
                    />
                  </td>
                  <td>
                    <input
                      className="input-number"
                      type="number"
                      name={`prices.${i}`}
                      onChange={handleInvoiceFieldUpdate}
                      value={invoice.prices[i]}
                    />
                  </td>
                  <td>
                    <input
                      className="input-number"
                      type="number"
                      name={`quantities.${i}`}
                      onChange={handleInvoiceFieldUpdate}
                      value={invoice.quantities[i]}
                    />
                  </td>
                  <td>
                    <span
                      className="btn-clear"
                      onClick={() => handleDeleteItem(i)}
                    >
                      ❌
                    </span>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div>
            <label htmlFor="subtotal">Subtotal:</label>
            <input
              type="number"
              name="subtotal"
              onChange={handleInvoiceFieldUpdate}
              value={invoice.subtotal}
            />
          </div>
          <div>
            <label htmlFor="tax">Taxes:</label>
            <input
              type="number"
              name="tax"
              onChange={handleInvoiceFieldUpdate}
              value={invoice.tax}
            />
          </div>
          <div>
            <label htmlFor="total">Total:</label>
            <input
              type="number"
              name="total"
              value={roundMoney(invoice.subtotal + invoice.tax)}
              disabled
            />
          </div>
          <div>
            <label htmlFor="notes">Notes:</label>
            <textarea
              name="notes"
              onChange={handleInvoiceFieldUpdate}
              value={invoice.notes}
            />
          </div>
          <div>
            <label htmlFor="invoice_status">Status:</label>
            <StaticOptionDropdown
              name="invoice_status"
              value={invoice.invoice_status}
              onSelect={handleInvoiceFieldUpdate}
              staticOptionKey={soKeyInvoiceStatus}
              hideDefault
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editInvoice : addInvoice}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Invoices;
