import React, {useState, useEffect} from 'react';
import {debounce, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {Link, useLocation} from 'react-router-dom';
import {fnGetClients, fnAddClient, fnEditClient} from '../../api';
import {
  logError,
  getFullLocation,
  responseDataExists,
  nowDateString,
  displayDate,
  handleResetDoneCallback,
  handleOnClickSortableTableHeader,
} from '../../helpers';
import Filters from '../../components/Filters';
import SortableTableHeader from '../../components/SortableTableHeader';

import './style.css';

export function Clients() {
  const pageName = 'Client List';

  const defaultClient = () => {
    return {
      client_id: null,
      name: '',
      company: '',
      email: '',
      phone: '',
      address: '',
      ts_created: nowDateString(),
    }
  };

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(defaultClient());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [sortables, setSortables] = useState({column: null, reset: false});
  const location = useLocation();

  function handleOpenModal(client) {
    if (!client) {
      client = defaultClient();
    } else {
      client = cloneDeep(client);
    }

    setClient(client);
    setModalDetails({...modalDetails, show: true, isEdit: client.client_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleClientFieldUpdate(e) {
    const updatedClient = {...client};
    updatedClient[e.target.name] = e.target.value;
    setClient(updatedClient);
  }

  function getClients(filters = {search: ''}) {
    const data = {search: filters.search};

    fnGetClients(data).then((response) => {
      console.log('clients:\n', response.data);
      if (responseDataExists(response)) {
        // reset sortable table headers
        handleOnClickSortableTableHeader();

        setClients(response.data.data || []);
      }
    }).catch(logError)
  }

  function addClient() {
    if (client) {
      fnAddClient(client).then((response) => {
        console.log('add client:\n', response.data);
        if (response.data && response.data.data) {
          setClients([response.data.data, ...clients]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function editClient() {
    if (client) {
      fnEditClient(client.client_id, client).then((response) => {
        console.log('edit client:\n', response.data);
        if (response.data && response.data.data) {
          setClients(clients.map((c) => c.client_id === client.client_id ? response.data.data : c));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  const delayGetClients = debounce((...args) => getClients(...args), 1000);

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>{pageName}</h1>
        <div className="right">
          <Filters
            onChangeSearch={delayGetClients}
            hideDateRange
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>Name</th>
            <th>Company Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Project History</th>
            <th>Email Logs</th>
            <th>Quotes</th>
            <th>Invoices</th>
            <SortableTableHeader
              list={clients}
              getCompareElement={(c) => c ? new Date(c.ts_created).getTime() : null}
              setList={setClients}
              reset={sortables.column !== 'added' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('added', sortables, setSortables)}
            >
              Added
            </SortableTableHeader>
          </tr>
          </thead>
          <tbody>
          {clients.map((client, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal(client)}>{client.name}</td>
              <td onClick={() => handleOpenModal(client)}>{client.company}</td>
              <td onClick={() => handleOpenModal(client)}>{client.email}</td>
              <td onClick={() => handleOpenModal(client)}>{client.phone}</td>
              <td onClick={() => handleOpenModal(client)}>{client.address}</td>
              <td>
                <Link
                  to={`/clients/${client.client_id}/project_history`}
                  state={{back: getFullLocation(location), backName: pageName, client: client.name}}
                >
                  History
                </Link>
              </td>
              <td></td>
              <td>
                <Link
                  to={`/quotes`}
                  state={{clientID: client.client_id}}
                >
                  View
                </Link>
              </td>
              <td>
                <Link
                  to={`/invoices`}
                  state={{clientID: client.client_id}}
                >
                  View
                </Link>
              </td>
              <td onClick={() => handleOpenModal(client)}>{displayDate(client.ts_created)}</td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Client</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleClientFieldUpdate}
              value={client.name}
            />
          </div>
          <div>
            <label htmlFor="company">Company:</label>
            <input
              type="text"
              name="company"
              onChange={handleClientFieldUpdate}
              value={client.company}
            />
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              name="email"
              onChange={handleClientFieldUpdate}
              value={client.email}
            />
          </div>
          <div>
            <label htmlFor="phone">Phone:</label>
            <input
              type="text"
              name="phone"
              onChange={handleClientFieldUpdate}
              value={client.phone}
            />
          </div>
          <div>
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              name="address"
              onChange={handleClientFieldUpdate}
              value={client.address}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editClient : addClient}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Clients;
