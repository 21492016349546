import {createContext} from 'react';

export const soKeyEstimateStatus = 'estimate_status';
export const soKeyInvoiceStatus = 'invoice_status';
export const soKeyLeadStatus = 'lead_status';
export const soKeyLogTypes = 'log_types';
export const soKeyMemberPositions = 'member_positions';
export const soKeyProjectStatus = 'project_status';

export const StaticOptionsContext = createContext(() => {
  const obj = {};
  obj[soKeyEstimateStatus] = [];
  obj[soKeyInvoiceStatus] = [];
  obj[soKeyLeadStatus] = [];
  obj[soKeyLogTypes] = [];
  obj[soKeyMemberPositions] = [];
  obj[soKeyProjectStatus] = [];
  return obj;
});

export const routeParamClientID = 'clientID';
export const routeParamEstimateID = 'estimateID';
export const routeParamInvoiceID = 'invoiceID';
export const routeParamLeadID = 'leadID';
export const routeParamProjectID = 'projectID';
export const routeParamQuoteID = 'quoteID';

// format client options as `{company} ({name})`
export function displayClientOption(client = {}) {
  if (client) {
    return `${client.company} (${client.name})`;
  }
}

// displayDate in format 'YYYY-MM-DD'
export function displayDate(value = '') {
  const date = new Date(value);
  const y = date.getUTCFullYear();
  let m = date.getUTCMonth();
  const d = date.getUTCDate();

  // month is zero-based
  m += 1;

  return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;
}

// format lead options as `{company} ({name})`
export function displayLeadOption(lead = {}) {
  if (lead) {
    return `${lead.company} (${lead.name})`;
  }
}

// displayMoney in format '$d.dd'
export function displayMoney(value = 0) {
  if (typeof value === 'number') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(value);
  } else {
    return '$0.00';
  }
}

// format product service option as `{name} ({price})`
export function displayProductServiceOption(productService = {}) {
  if (productService) {
    return `${productService.name} (${productService.price})`;
  }
}

// format project options as `{client_name} ({name})`
export function displayProjectOption(project = {}) {
  if (project) {
    return `${project.client_name} (${project.name})`;
  }
}

// format team member options as `{name} ({phone})`
export function displayTeamMemberOption(member = {}) {
  if (member) {
    return `${member.name} (${member.phone})`;
  }
}

// displayTime in formats 'hh:mm' OR 'h:mm am'
export function displayTime(value = '', in12Hours = false, inUTC = false) {
  const date = new Date(value);
  let h = inUTC ? date.getUTCHours() : date.getHours();
  const m = inUTC ? date.getUTCMinutes() : date.getMinutes();

  let display = `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;
  if (in12Hours) {
    let ind = 'am';
    if (h >= 12) {
      ind = 'pm';
      if (h > 12) {
        h -= 12;
      }
    } else if (h === 0) {
      h = 12;
    }

    display = `${h}:${m < 10 ? `0${m}` : m} ${ind}`;
  }

  return display;
}

export function displayYesNo(value = false) {
  return value === true ? 'Yes' : 'No';
}

export function formatDateToLocale(value = '') {
  const date = new Date(value);
  const y = date.getUTCFullYear();
  const m = date.getUTCMonth();
  const d = date.getUTCDate();

  const localeDate = new Date();
  localeDate.setFullYear(y, m, d);
  return localeDate;
}

// getFullLocation concatenate the location path pieces to get original path
export function getFullLocation(location = {}) {
  return location.pathname + location.search + location.hash;
}

// function callback after reset sortables columns completed
export function handleResetDoneCallback(sortables = {column: null, reset: false}, setSortables = () => {
}) {
  const updatedSortables = {...sortables};
  updatedSortables.reset = false;
  setSortables(updatedSortables);
}

// function to update sortable columns
export function handleOnClickSortableTableHeader(column = null, sortables = {
  column: null,
  reset: false
}, setSortables = () => {
}) {
  const updatedSortables = {...sortables};
  updatedSortables.column = column;
  updatedSortables.reset = true;
  setSortables(updatedSortables);
}

export function logError(error = {}) {
  if (error) {
    console.log(error);
  }
}

// nowDateString returns UTC
export function nowDateString() {
  return new Date().toJSON();
}

// nowLocaleDateString returns UTC relative to locale
export function nowLocaleDateString() {
  const date = new Date();
  if (date.getDate() !== date.getUTCDate()) {
    date.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
  }

  return date.toJSON();
}

// responseDataExists checks to see if response contains data field
export function responseDataExists(response = {}) {
  return response.data && typeof response.data.data !== 'undefined';
}

// roundMoney rounds value to 2 decimal places
export function roundMoney(value = 0.0) {
  if (typeof value === 'number') {
    return Math.round(value * 100) / 100;
  } else {
    return 0.0;
  }
}
