import React, {useState, useEffect} from 'react';
import {debounce, sortBy, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {Link, useLocation} from 'react-router-dom';
import {fnGetProjects, fnAddProject, fnEditProject, fnGetClients, fnGetTeamMembers, fnGetProjectDoc} from '../../api';
import {
  soKeyProjectStatus,
  logError,
  displayYesNo,
  displayDate,
  nowDateString,
  nowLocaleDateString,
  getFullLocation,
  responseDataExists,
  displayTeamMemberOption,
  displayClientOption,
  handleResetDoneCallback,
  handleOnClickSortableTableHeader,
} from '../../helpers';
import YesNoDropdown from '../../components/YesNoDropdown';
import DatePicker from '../../components/DatePicker';
import StaticOptionDropdown from '../../components/StaticOptionDropdown';
import SearchDropdown from '../../components/SearchDropdown';
import Filters from '../../components/Filters';
import SortableTableHeader from '../../components/SortableTableHeader';

import './style.css';

export function Projects() {
  const pageName = 'Project Tracking';
  const statusOrderMap = {
    'Not Started': 0,
    Deposited: 1,
    Started: 2,
    Invoiced: 3,
    Completed: 4,
    Late: 5,
  };

  const defaultProject = () => {
    return {
      project_id: null,
      client_id: null,
      lead_id: null,
      estimate_id: null,
      status: 'Not Started',
      deposit_num: '',
      ts_deposit_received: null,
      invoice_num: '',
      ts_invoice_paid: null,
      estimate_num: null,
      po: '',
      schedule_document_id: null,
      client_name: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
      name: '',
      address: '',
      city: '',
      start_date: nowLocaleDateString(),
      end_date: null,
      estimated_days: 0,
      estimated_hours: 0,
      actual_days: 0,
      actual_hours: 0,
      pm_ids: [],
      supervisor_ids: [],
      labourer_ids: [],
      dumpster_required: false,
      dumpster_quantity: 0,
      dumpster_dimensions: '',
      ts_dumpster_received: null
    }
  };

  const defaultMemberOptions = () => {
    return {
      labourers: [
        // {
        //   member_id: null,
        //   name: '',
        //   phone: '',
        //   address: '',
        //   pay_rate: 0,
        //   position: 'PM',
        //   notes: ''
        // }
      ],
      pms: [],
      supervisors: [],
      idToMember: {},
    }
  };

  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(defaultProject());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});
  const [clientOptions, setClientOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState(defaultMemberOptions());
  const [sortables, setSortables] = useState({column: null, reset: false});
  const location = useLocation();

  function handleOpenModal(project) {
    if (!project) {
      project = defaultProject();
    } else {
      project = cloneDeep(project);
    }

    setProject(project);
    setModalDetails({...modalDetails, show: true, isEdit: project.project_id !== null});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleProjectFieldUpdate(e) {
    const updatedProject = {...project};
    const name = e.target.name;
    let value = e.target.value;

    switch (name) {
      case 'client_id':
        if (value) {
          // default fill fields with client data
          if (value.name && !updatedProject.contact_name) {
            updatedProject.contact_name = value.name;
          }
          if (value.email && !updatedProject.contact_email) {
            updatedProject.contact_email = value.email;
          }
          if (value.phone && !updatedProject.contact_phone) {
            updatedProject.contact_phone = value.phone;
          }
          if (value.company && !updatedProject.client_name) {
            updatedProject.client_name = value.company;
          }

          // reassign value for updating
          value = value.client_id;
        }
        break;
      case 'ts_deposit_received':
      case 'ts_invoice_paid':
      case 'ts_dumpster_received':
        // convert bool to a timestamp or old timestamp
        if (value) {
          if (typeof project.i !== 'undefined'
            && projects[project.i].project_id === project.project_id && projects[project.i][name]) {
            value = projects[project.i][name];
          } else {
            value = nowDateString();
          }
        } else {
          value = null;
        }
        console.log(name, value);
        break;
      case 'start_date':
      case 'end_date':
        if (value) {
          value = value.toJSON();
        }
        break;
      default:
    }

    updatedProject[name] = value;
    setProject(updatedProject);
  }

  function handleAddMember(e) {
    const updatedProject = {...project};
    const value = e.target.value;

    if (value) {
      updatedProject[e.target.name].unshift(value.member_id);
      setProject(updatedProject);
    }
  }

  function handleDeleteMember(key, i) {
    const updatedProject = {...project};

    if (key) {
      updatedProject[key].splice(i, 1);
      setProject(updatedProject);
    }
  }

  function sanitizeProject(project = {}) {
    const sanitizedProject = {...project};
    // convert string to int
    if (typeof sanitizedProject.dumpster_quantity === 'string') {
      sanitizedProject.dumpster_quantity = parseInt(sanitizedProject.dumpster_quantity)
    }

    return sanitizedProject;
  }

  function getProjects(filters = {search: ''}) {
    const data = {search: filters.search};
    if (filters.fromTs && filters.toTs) {
      data.filter_from_ts = filters.fromTs;
      data.filter_to_ts = filters.toTs;
    }

    fnGetProjects(data).then((response) => {
      console.log('projects:\n', response.data);
      if (responseDataExists(response)) {
        setProjects(response.data.data || []);
      }
    }).catch(logError)
  }

  function addProject() {
    if (project) {
      fnAddProject(sanitizeProject(project)).then((response) => {
        console.log('add project:\n', response.data);
        if (response.data && response.data.data) {
          setProjects([response.data.data, ...projects]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function editProject() {
    if (project) {
      fnEditProject(project.project_id, sanitizeProject(project)).then((response) => {
        console.log('edit project:\n', response.data);
        if (response.data && response.data.data) {
          setProjects(projects.map((p) => p.project_id === project.project_id ? response.data.data : p));
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  function getClientOptions(search = '') {
    fnGetClients({search: search}).then((response) => {
      console.log('client options:\n', response.data);
      if (responseDataExists(response)) {
        setClientOptions(
          sortBy(
            (response.data.data || []).map((o) => {
              o.displayOption = displayClientOption(o);
              return o;
            }),
            (o) => o.displayOption
          )
        );
      }
    }).catch(logError)
  }

  function getMemberOptions(search = '') {
    fnGetTeamMembers({search: search}).then((response) => {
      console.log('member options:\n', response.data);
      if (responseDataExists(response)) {
        const updatedMemberOptions = defaultMemberOptions();
        if (response.data.data) {
          // split up members by position and build mapping from id to member
          response.data.data.forEach((m) => {
            updatedMemberOptions.idToMember[m.member_id] = m;
            m.displayOption = displayTeamMemberOption(m);
            switch (m.position) {
              case 'Labourer':
                updatedMemberOptions.labourers.push(m);
                break;
              case 'PM':
                updatedMemberOptions.pms.push(m);
                break;
              case 'Supervisor':
                updatedMemberOptions.supervisors.push(m);
                break;
              case 'Estimator':
                break;
              default:
                console.log(`unrecognized member positions: "${m.position}"`);
            }
          });

          // sort member options by name and phone number
          sortBy(updatedMemberOptions.labourers, (o) => o.displayOption);
          sortBy(updatedMemberOptions.pms, (o) => o.displayOption);
          sortBy(updatedMemberOptions.supervisors, (o) => o.displayOption);
        }

        setMemberOptions(updatedMemberOptions);
      }
    }).catch(logError)
  }

  function getProjectDoc(project = {}) {
    if (project && project.project_id && project.schedule_document_id) {
      fnGetProjectDoc(project.project_id, project.schedule_document_id).then((response) => {
        if (response.data && response.data.data) {
          console.log('download project doc:\n', response.data);
          window.open(response.data.data, '_blank');
        }
      })
    }
  }

  const delayGetProjects = debounce((...args) => getProjects(...args), 1000);

  useEffect(() => {
    getProjects();
    getClientOptions();
    getMemberOptions();
  }, []);

  return (
    <div>
      <div className="page-header">
        <h1>{pageName}</h1>
        <div className="right">
          <Filters
            onChangeSearch={delayGetProjects}
            onChangeDateRange={getProjects}
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <SortableTableHeader
              list={projects}
              getCompareElement={(p) => p ? statusOrderMap[p.status] : null}
              setList={setProjects}
              reset={sortables.column !== 'status' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('status', sortables, setSortables)}
            >
              Project Status
            </SortableTableHeader>
            <th>Deposit Invoice #</th>
            <SortableTableHeader
              list={projects}
              getCompareElement={(p) => p ? ![null, ''].includes(p.ts_deposit_received) === false : null}
              setList={setProjects}
              reset={sortables.column !== 'deposit-received' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('deposit-received', sortables, setSortables)}
            >
              Deposit Received
            </SortableTableHeader>
            <th>Invoice Number</th>
            <SortableTableHeader
              list={projects}
              getCompareElement={(p) => p ? ![null, ''].includes(p.ts_invoice_paid) === false : null}
              setList={setProjects}
              reset={sortables.column !== 'invoice-paid' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('invoice-paid', sortables, setSortables)}
            >
              Invoice Paid
            </SortableTableHeader>
            <th>Estimate Number</th>
            <th>PO</th>
            <th>Schedule Received</th>
            <th>Client Name</th>
            <th>Contact Name</th>
            <th>Contact Phone</th>
            <th>Contact Email</th>
            <th>Project Name</th>
            <th>Address</th>
            <th>City</th>
            <th>Start</th>
            <th>End</th>
            <th>Estimated Duration Days</th>
            <th>Estimated Duration Hours</th>
            <th>Actual Duration Days</th>
            <th>Actual Duration Hours</th>
            <th>Deviation from Target</th>
            <th>Supervisor</th>
            <th>PM</th>
            <th>Labourer</th>
            <SortableTableHeader
              list={projects}
              getCompareElement={(p) => p ? p.dumpster_required === false : null}
              setList={setProjects}
              reset={sortables.column !== 'dumpster-required' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('dumpster-required', sortables, setSortables)}
            >
              Dumpster Required
            </SortableTableHeader>
            <th>Quantity of Dumpsters</th>
            <SortableTableHeader
              list={projects}
              getCompareElement={(p) => p ? ![null, ''].includes(p.ts_dumpster_received) === false : null}
              setList={setProjects}
              reset={sortables.column !== 'dumpster-received' && sortables.reset}
              resetDoneCallback={() => handleResetDoneCallback(sortables, setSortables)}
              onClick={() => handleOnClickSortableTableHeader('dumpster-received', sortables, setSortables)}
            >
              Dumpster Received
            </SortableTableHeader>
            <th>Dumpster Dimensions</th>
            <th>Notes</th>
            <th>Documents</th>
          </tr>
          </thead>
          <tbody>
          {projects.map((project, i) => (
            <tr
              className="table-row"
              key={i}
            >
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.status}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.deposit_num}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {displayYesNo(![null, ''].includes(project.ts_deposit_received))}
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.invoice_num}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {displayYesNo(![null, ''].includes(project.ts_invoice_paid))}
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.estimate_num}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.po}</td>
              <td>
                {project.schedule_document_id ?
                  <span
                    className="btn-download"
                    onClick={() => getProjectDoc(project)}
                  >
                    download
                  </span>
                  :
                  null
                }
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.client_name}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.contact_name}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.contact_phone}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.contact_email}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.name}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.address}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.city}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {displayDate(project.start_date)}
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {project.end ? displayDate(project.end_date) : null}
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.estimated_days}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.estimated_hours}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.actual_days}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.actual_hours}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {project.actual_hours - project.estimated_hours}
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {project.supervisor_ids.length > 0 ?
                  `${memberOptions.idToMember[project.supervisor_ids[0]] ?
                    memberOptions.idToMember[project.supervisor_ids[0]].name : '[removed member]'}
                    ${project.supervisor_ids.length > 1 ? ', ...' : ''}`
                  :
                  ''
                }
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {project.pm_ids.length > 0 ?
                  `${memberOptions.idToMember[project.pm_ids[0]] ?
                    memberOptions.idToMember[project.pm_ids[0]].name : '[removed member]'}
                    ${project.pm_ids.length > 1 ? ', ...' : ''}`
                  :
                  ''
                }
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {project.labourer_ids.length > 0 ?
                  `${memberOptions.idToMember[project.labourer_ids[0]] ?
                    memberOptions.idToMember[project.labourer_ids[0]].name : '[removed member]'}
                    ${project.labourer_ids.length > 1 ? ', ...' : ''}`
                  :
                  ''
                }
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {displayYesNo(project.dumpster_required)}
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.dumpster_quantity}</td>
              <td onClick={() => handleOpenModal({...project, i: i})}>
                {displayYesNo(![null, ''].includes(project.ts_dumpster_received))}
              </td>
              <td onClick={() => handleOpenModal({...project, i: i})}>{project.dumpster_dimensions}</td>
              <td>
                <Link
                  to={`/projects/${project.project_id}/notes`}
                  state={{back: getFullLocation(location), backName: pageName}}
                >
                  notes
                </Link>
              </td>
              <td>
                <Link
                  to={`/projects/${project.project_id}/documents`}
                  state={{back: getFullLocation(location), backName: pageName}}
                >
                  documents
                </Link>
              </td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'Edit' : 'New'} Project</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!modalDetails.isEdit ?
            <div>
              <label htmlFor="client_id">Client:</label>
              <SearchDropdown
                name="client_id"
                value={project.client_id}
                onSelect={handleProjectFieldUpdate}
                options={clientOptions}
                fnDisplayOption={(option) => option.displayOption}
                fnValueMatchOption={(value, option) => value === option.client_id}
              />
            </div>
            :
            null
          }
          <div>
            <label htmlFor="status">Status:</label>
            <StaticOptionDropdown
              name="status"
              value={project.status}
              onSelect={handleProjectFieldUpdate}
              staticOptionKey={soKeyProjectStatus}
            />
          </div>
          <div>
            <label htmlFor="deposit_num">Deposit Invoice #:</label>
            <input
              type="text"
              name="deposit_num"
              onChange={handleProjectFieldUpdate}
              value={project.deposit_num || ''}
            />
          </div>
          <div>
            <label htmlFor="ts_deposit_received">Deposit Received:</label>
            <YesNoDropdown
              name="ts_deposit_received"
              onSelect={handleProjectFieldUpdate}
              value={![null, ''].includes(project.ts_deposit_received)}
            />
          </div>
          <div>
            <label htmlFor="invoice_num">Invoice Number:</label>
            <input
              type="text"
              name="invoice_num"
              onChange={handleProjectFieldUpdate}
              value={project.invoice_num}
            />
          </div>
          <div>
            <label htmlFor="ts_invoice_paid">Invoice Paid:</label>
            <YesNoDropdown
              name="ts_invoice_paid"
              onSelect={handleProjectFieldUpdate}
              value={![null, ''].includes(project.ts_invoice_paid)}
            />
          </div>
          <div>
            <label htmlFor="estimate_num">Estimate Number:</label>
            <input
              type="text"
              name="estimate_num"
              onChange={handleProjectFieldUpdate}
              value={project.estimate_num || ''}
            />
          </div>
          <div>
            <label htmlFor="po">PO:</label>
            <input
              type="text"
              name="po"
              onChange={handleProjectFieldUpdate}
              value={project.po}
            />
          </div>
          <div>
            {/*TODO*/}
            <label htmlFor="schedule_received">Schedule Received:</label>
            <input
              type="text"
              name="schedule_received"
              onChange={handleProjectFieldUpdate}
              value={''}
            />
          </div>
          <div>
            <label htmlFor="client_name">Client Name:</label>
            <input
              type="text"
              name="client_name"
              onChange={handleProjectFieldUpdate}
              value={project.client_name}
            />
          </div>
          <div>
            <label htmlFor="contact_name">Contact Name:</label>
            <input
              type="text"
              name="contact_name"
              onChange={handleProjectFieldUpdate}
              value={project.contact_name}
            />
          </div>
          <div>
            <label htmlFor="contact_phone">Contact Phone:</label>
            <input
              type="text"
              name="contact_phone"
              onChange={handleProjectFieldUpdate}
              value={project.contact_phone}
            />
          </div>
          <div>
            <label htmlFor="contact_email">Contact Email:</label>
            <input
              type="text"
              name="contact_email"
              onChange={handleProjectFieldUpdate}
              value={project.contact_email}
            />
          </div>
          <div>
            <label htmlFor="name">Project Name:</label>
            <input
              type="text"
              name="name"
              onChange={handleProjectFieldUpdate}
              value={project.name}
            />
          </div>
          <div>
            {/*TODO*/}
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              name="address"
              onChange={handleProjectFieldUpdate}
              value={project.address}
            />
          </div>
          <div>
            <label htmlFor="city">City:</label>
            <input
              type="text"
              name="city"
              onChange={handleProjectFieldUpdate}
              value={project.city}
            />
          </div>
          <div>
            <label htmlFor="start_date">Start:</label>
            <DatePicker
              name="start_date"
              value={project.start_date}
              onSelect={handleProjectFieldUpdate}
            />
          </div>
          <div>
            <label htmlFor="end_date">End:</label>
            <DatePicker
              name="end_date"
              value={project.end_date || ''}
              onSelect={handleProjectFieldUpdate}
              showClear={true}
            />
          </div>
          <div>
            <label htmlFor="estimated_days">Estimated Duration Days:</label>
            <input
              type="number"
              name="estimated_days"
              onChange={handleProjectFieldUpdate}
              value={project.estimated_days}
            />
          </div>
          <div>
            <label htmlFor="estimated_hours">Estimated Duration Hours:</label>
            <input
              type="number"
              name="estimated_hours"
              onChange={handleProjectFieldUpdate}
              value={project.estimated_hours}
            />
          </div>
          <div>
            <label htmlFor="actual_days">Actual Duration Days:</label>
            <input
              type="number"
              name="actual_days"
              onChange={handleProjectFieldUpdate}
              value={project.actual_days}
            />
          </div>
          <div>
            <label htmlFor="actual_hours">Actual Duration Hours:</label>
            <input
              type="number"
              name="actual_hours"
              onChange={handleProjectFieldUpdate}
              value={project.actual_hours}
            />
          </div>
          <div className="sub-questions">
            <h5>Supervisors:</h5>
            <div>
              <SearchDropdown
                name="supervisor_ids"
                value={null}
                onSelect={handleAddMember}
                options={memberOptions.supervisors.filter((m) => !project.supervisor_ids.includes(m.member_id))}
                fnDisplayOption={(option) => option.displayOption}
                placeholder="Supervisor"
                dismissSelected={true}
              />
            </div>
            <table>
              <tbody>
              {project.supervisor_ids.map((id, i) => (
                <tr key={i}>
                  <td>
                    {memberOptions.idToMember[id] ? memberOptions.idToMember[id].displayOption : 'old member removed'}
                  </td>
                  <td>
                    <span
                      className="btn-clear"
                      onClick={() => handleDeleteMember('supervisor_ids', i)}
                    >
                      ❌
                    </span>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div className="sub-questions">
            <h5>PMs:</h5>
            <div>
              <SearchDropdown
                name="pm_ids"
                value={null}
                onSelect={handleAddMember}
                options={memberOptions.pms.filter((m) => !project.pm_ids.includes(m.member_id))}
                fnDisplayOption={(option) => option.displayOption}
                placeholder="PM"
                dismissSelected={true}
              />
            </div>
            <table>
              <tbody>
              {project.pm_ids.map((id, i) => (
                <tr key={i}>
                  <td>
                    {memberOptions.idToMember[id] ? memberOptions.idToMember[id].displayOption : 'old member removed'}
                  </td>
                  <td>
                    <span
                      className="btn-clear"
                      onClick={() => handleDeleteMember('pm_ids', i)}
                    >
                      ❌
                    </span>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div className="sub-questions">
            <h5>Labourers:</h5>
            <div>
              <SearchDropdown
                name="labourer_ids"
                value={null}
                onSelect={handleAddMember}
                options={memberOptions.labourers.filter((m) => !project.labourer_ids.includes(m.member_id))}
                fnDisplayOption={(option) => option.displayOption}
                placeholder="Supervisor"
                dismissSelected={true}
              />
            </div>
            <table>
              <tbody>
              {project.labourer_ids.map((id, i) => (
                <tr key={i}>
                  <td>
                    {memberOptions.idToMember[id] ? memberOptions.idToMember[id].displayOption : 'old member removed'}
                  </td>
                  <td>
                    <span
                      className="btn-clear"
                      onClick={() => handleDeleteMember('labourer_ids', i)}
                    >
                      ❌
                    </span>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div>
            <label htmlFor="dumpster_required">Dumpster Required:</label>
            <YesNoDropdown
              name="dumpster_required"
              onSelect={handleProjectFieldUpdate}
              value={project.dumpster_required || false}
            />
          </div>
          <div>
            <label htmlFor="dumpster_quantity">Quantity of Dumpsters:</label>
            <input
              type="number"
              name="dumpster_quantity"
              onChange={handleProjectFieldUpdate}
              value={project.dumpster_quantity}
            />
          </div>
          <div>
            <label htmlFor="ts_dumpster_received">Dumpster Received:</label>
            <YesNoDropdown
              name="ts_dumpster_received"
              onSelect={handleProjectFieldUpdate}
              value={![null, ''].includes(project.ts_dumpster_received)}
            />
          </div>
          <div>
            <label htmlFor="dumpster_dimensions">Dumpster Dimensions:</label>
            <input
              type="text"
              name="dumpster_dimensions"
              onChange={handleProjectFieldUpdate}
              value={project.dumpster_dimensions}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn-confirm"
            onClick={modalDetails.isEdit ? editProject : addProject}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Projects;
