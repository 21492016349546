import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {debounce, isEqual} from 'lodash';

import './style.css';

class SearchDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      filteredOptions: [...this.props.options],
      selectedOption: undefined, // to allow setting initial value
    };

    this.delaySearch = debounce((search) => {
      const {options, fnDisplayOption} = this.props;

      let filteredOptions = this.props.options;
      if (options) {
        if (fnDisplayOption) {
          filteredOptions = options.filter((o) =>
            (fnDisplayOption(o) || '').toLowerCase().indexOf(search.toLowerCase()) >= 0
          );
        } else {
          filteredOptions = options.filter((o) =>
            typeof o.toString === 'function' && o.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0
          );
        }
      }

      this.setState({...this.state, filteredOptions});
    }, 500)
  }

  componentDidMount() {
    const {options, value, fnValueMatchOption} = this.props;
    if (value && fnValueMatchOption && (options || []).length > 0 && typeof this.state.selectedOption === 'undefined') {
      this.handleOnChange((options.filter((o) => fnValueMatchOption(value, o)) || [])[0] || null);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {options, value, fnValueMatchOption} = this.props;
    options = options || [];
    const prevOptions = prevProps.options || [];
    if (prevOptions.length !== options.length || !isEqual(prevOptions, options)) {
      this.delaySearch(this.state.search);
    }
    if (value && fnValueMatchOption && (options || []).length > 0 && typeof prevState.selectedOption === 'undefined') {
      this.handleOnChange((options.filter((o) => fnValueMatchOption(value, o)) || [])[0] || null);
    }
  }

  handleOnChange = (value) => {
    this.setState({...this.state, selectedOption: !this.props.dismissSelected ? value : null});
    if (this.props.onSelect) {
      this.props.onSelect({target: {name: this.props.name || '', value: value}});
    }
  };

  handleSearchChange = (value) => {
    this.setState({...this.state, search: value});
    this.delaySearch(value);
  };

  handleSearchClear = () => {
    this.setState({
      ...this.state,
      search: '',
      selectedOption: this.props.resetValue ? undefined : null,
      filteredOptions: [...this.props.options]
    });
    if (this.props.onSelect) {
      this.props.onSelect({target: {name: this.props.name || '', value: null}});
    }
  };

  render() {
    const {fnDisplayOption, fnValueMatchOption, placeholder, className, onClick} = this.props;
    const {selectedOption, filteredOptions} = this.state;

    return (
      <DropdownButton
        className={`search-dropdown${!selectedOption && placeholder ? ' my-placeholder' : ''} ${className}`}
        name={this.props.name || ''}
        title={selectedOption && fnDisplayOption ? fnDisplayOption(selectedOption) : selectedOption || placeholder || ''}
        disabled={this.props.disabled}
        onClick={!this.props.disabled && onClick ? onClick : () => {
        }}
      >
        <div className="search-ctn">
          <input
            type="text"
            value={this.state.search}
            onChange={(e) => this.handleSearchChange(e.target.value)}
            placeholder="Search"
          />
          {this.props.showClear && selectedOption ?
            <span
              className="btn-clear"
              onClick={this.handleSearchClear}
            >
              ❌
            </span>
            :
            null
          }
        </div>
        {filteredOptions.map((option, i) =>
          <Dropdown.Item
            as="button"
            key={i}
            onClick={() => this.handleOnChange(option)}
            active={fnValueMatchOption ? fnValueMatchOption(this.props.value, option) : false}
          >
            {fnDisplayOption ? fnDisplayOption(option) : option}
          </Dropdown.Item>
        )}
      </DropdownButton>
    )
  }
}

SearchDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onSelect: PropTypes.func,
  options: PropTypes.array,
  fnDisplayOption: PropTypes.func, // function(option)
  fnValueMatchOption: PropTypes.func, // function(value, option)
  showClear: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  dismissSelected: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  resetValue: PropTypes.bool,
};

export default SearchDropdown;