import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import './style.css';

class ConfirmationModal extends React.Component {
  render() {
    const {show, title, messages, onOK, onCancel} = this.props;

    return (
      <Modal
        className="new-edit-modal"
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {(messages || []).map((m, i) => (
            <p key={i}>{m}</p>
          ))}
          {this.props.children}
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-confirm"
            onClick={onOK}
          >
            OK
          </button>
          <button
            className="btn-cancel"
            onClick={onCancel}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  messages: PropTypes.array,
  onOK: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ConfirmationModal;