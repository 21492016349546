import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {StaticOptionsContext} from '../../helpers';

import './style.css';

function StaticOptionDropdown({name = '', value = '', onSelect = null, staticOptionKey = '', hideDefault = false}) {
  const staticOptions = useContext(StaticOptionsContext);

  function handleOnChange(value) {
    if (onSelect) {
      onSelect({target: {name: name || '', value: value}});
    }
  }

  // default value
  value = value || (hideDefault ? '' : staticOptions[staticOptionKey][0]) || '';

  return (
    <DropdownButton
      className="static-option-dropdown"
      name={name}
      title={value}>
      {(staticOptions[staticOptionKey] || []).map((status, index) => (
        <Dropdown.Item
          as="button"
          key={index}
          onClick={() => handleOnChange(status)}
          active={value === status}
        >
          {status}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  )
}

StaticOptionDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onSelect: PropTypes.func,
  staticOptionKey: PropTypes.string,
  hideDefault: PropTypes.bool,
};

export default StaticOptionDropdown;