import React from 'react';
import PropTypes from 'prop-types';
import 'rc-time-picker/assets/index.css';
import {sortBy} from "lodash";
import DateRangePicker from '../DateRangePicker';
import SearchDropdown from '../../components/SearchDropdown';
import {logError, responseDataExists, displayProjectOption, displayClientOption} from "../../helpers";
import {fnGetProjects, fnGetClients} from '../../api';

import './style.css';

class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      fromTs: null,
      toTs: null,
      projectID: null,
      clientID: this.props.initialClientID ? this.props.initialClientID : undefined, // to allow setting initial value

      projectOptions: this.props.projectOptions ? this.sortProjectOptions(this.props.projectOptions) : [],
      clientOptions: this.props.clientOptions ? this.sortClientOptions(this.props.clientOptions) : [],
    };
  }

  sortProjectOptions = (options = []) => {
    return sortBy(
      (options || []).map((o) => {
        o.displayOption = displayProjectOption(o);
        return o;
      }),
      (o) => o.displayOption
    )
  };

  sortClientOptions = (options = []) => {
    return sortBy(
      (options || []).map((o) => {
        o.displayOption = displayClientOption(o);
        return o;
      }),
      (o) => o.displayOption
    )
  };

  componentDidMount() {
    if (this.props.showProject && typeof this.props.projectOptions === 'undefined') {
      // get project options if not provided
      fnGetProjects().then((response) => {
        console.log('filter project options:\n', response.data);
        if (responseDataExists(response)) {
          this.setState({projectOptions: this.sortProjectOptions(response.data.data || [])});
        }
      }).catch(logError)
    }
    if (this.props.showClient && typeof this.props.clientOptions === 'undefined') {
      // get client options if not provided
      fnGetClients().then((response) => {
        console.log('filter client options:\n', response.data);
        if (responseDataExists(response)) {
          this.setState({clientOptions: this.sortClientOptions(response.data.data || [])});
        }
      }).catch(logError)
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {showProject, projectOptions, showClient, clientOptions} = this.props;
    projectOptions = projectOptions || [];
    clientOptions = clientOptions || [];
    if (showProject && projectOptions.length > 0
      && (!prevProps.projectOptions || prevProps.projectOptions.length === 0)) {
      this.setState({projectOptions: this.sortProjectOptions(projectOptions)});
    }
    if (showClient && clientOptions.length > 0
      && (!prevProps.clientOptions || prevProps.clientOptions.length === 0)) {
      this.setState({clientOptions: this.sortClientOptions(clientOptions)});
    }
    if (this.props.showClient && this.props.initialClientID && typeof prevState.clientID === 'undefined') {
      this.setState({clientID: this.props.initialClientID});
    }
  }

  editSearch = (e) => {
    const filters = {...this.state};
    filters.search = e.target.value;
    this.setState(filters);
    if (this.props.onChangeSearch) {
      this.props.onChangeSearch(filters);
    }
  };

  handleDateRangeOnSelect = (range) => {
    if (typeof range.startDate !== 'undefined' && typeof range.endDate !== 'undefined') {
      const filters = {...this.state};
      filters.fromTs = range.startDate;
      filters.toTs = range.endDate;
      this.setState(filters);
      if (this.props.onChangeDateRange) {
        this.props.onChangeDateRange(filters);
      }
    }
  };

  handleProjectOnSelect = (e) => {
    const filters = {...this.state};
    const project = e.target.value;
    filters.projectID = (project && project.project_id) || null;
    this.setState(filters);
    if (this.props.onChangeProject) {
      this.props.onChangeProject(filters);
    }
  };

  handleClientOnSelect = (e) => {
    const filters = {...this.state};
    const client = e.target.value;
    filters.clientID = (client && client.client_id) || null;
    this.setState(filters);
    if (this.props.onChangeClient) {
      this.props.onChangeClient(filters);
    }
  };

  render() {
    return (
      <>
        {this.props.hideSearch === true ?
          null
          :
          <input
            type="text"
            className="search"
            placeholder="Search"
            value={this.state.search}
            onChange={this.editSearch}
          />
        }
        {this.props.hideDateRange === true ?
          null
          :
          <DateRangePicker
            onSelect={this.handleDateRangeOnSelect}
            disabledDay={this.props.disabledDay}
          />
        }
        {this.props.showProject === true ?
          <SearchDropdown
            value={this.state.projectID}
            onSelect={this.handleProjectOnSelect}
            options={this.state.projectOptions}
            fnDisplayOption={(option) => option.displayOption}
            fnValueMatchOption={(value, option) => value === option.project_id}
            showClear={true}
            placeholder="Project"
          />
          :
          null
        }
        {this.props.showClient === true ?
          <SearchDropdown
            value={this.state.clientID}
            onSelect={this.handleClientOnSelect}
            options={this.state.clientOptions}
            fnDisplayOption={(option) => option.displayOption}
            fnValueMatchOption={(value, option) => value === option.client_id}
            showClear={true}
            placeholder="Client"
          />
          :
          null
        }
      </>
    )
  }
}

Filters.propTypes = {
  onChangeSearch: PropTypes.func, // function(this.state)
  hideSearch: PropTypes.bool,
  onChangeDateRange: PropTypes.func, // function(this.state)
  hideDateRange: PropTypes.bool,
  disabledDay: PropTypes.func, // function(Date)
  onChangeProject: PropTypes.func, // function(this.state)
  showProject: PropTypes.bool,
  projectOptions: PropTypes.array, // [{project_id: null, client_name: '', name: ''}]
  onChangeClient: PropTypes.func, // function(this.state)
  showClient: PropTypes.bool,
  clientOptions: PropTypes.array, // [{client_id: null, company: '', name: ''}]
  initialClientID: PropTypes.number,
};

export default Filters;