import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {fnLogin} from '../../api';
import {logError} from '../../helpers';
import {saveStateWithKey} from '../../LocalStorage';

import './style.css';

export function Login() {
  const [credentials, setCredentials] = useState({email: '', password: ''});
  const navigate = useNavigate();
  const location = useLocation();

  function handleCredentialsUpdate(e) {
    const updatedCredentials = {...credentials};
    updatedCredentials[e.target.name] = e.target.value;
    setCredentials(updatedCredentials);
  }

  function login(e) {
    e.preventDefault();
    fnLogin({email: credentials.email, password: credentials.password}).then((response) => {
      console.log('login');
      if (response.data && response.data.data) {
        saveStateWithKey('X-Api-Key', response.data.data);
        navigate(location.state && location.state.destination ? location.state.destination : '/');
      }
    }).catch((error) => {
      logError(error);
      alert('Error logging in...');
    })
  }

  return (
    <div>
      <div className="page-header login">
        <form onSubmit={login}>
          <input
            type="text"
            name="email"
            value={credentials.email}
            onChange={handleCredentialsUpdate}
          />
          <input
            type="password"
            name="password"
            value={credentials.password}
            onChange={handleCredentialsUpdate}
          />
          <input
            type="submit"
            value="Login"
          />
        </form>
      </div>
    </div>
  )
}

export default Login;
