import React from 'react';
import PropTypes from 'prop-types';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {displayDate, nowLocaleDateString} from "../../helpers";

import './style.css';

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      startDate: null,
      endDate: null,
      oldStartDate: null,
      oldEndDate: null,
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({show: false});
    }
  };

  handleOnClick = () => {
    this.setState({show: !this.state.show});
  };

  handleOnChange = (ranges) => {
    let startDate = null;
    let endDate = null;

    if (ranges) {
      const value = ranges.selection;
      startDate = value.startDate;
      endDate = value.endDate;
    }

    this.setState({startDate, endDate});
  };

  handleOnSubmit = () => {
    const {startDate, endDate} = this.state;
    this.setState({oldStartDate: startDate, oldEndDate: endDate});
    if (this.props.onSelect) {
      this.props.onSelect({startDate, endDate});
    }
  };

  render() {
    const {show, startDate, endDate, oldStartDate, oldEndDate} = this.state;
    const selectionRange = {
      startDate: startDate ? startDate : new Date(nowLocaleDateString()),
      endDate: endDate ? endDate : new Date(nowLocaleDateString()),
      key: 'selection',
    };

    let disabledDay = () => false;
    if (this.props.disabledDay) {
      disabledDay = this.props.disabledDay;
    }

    // start or end dates do not match last submitted
    const changed =
      (
        (startDate !== null && oldStartDate !== null && startDate.toDateString() !== oldStartDate.toDateString())
        || (startDate === null && oldStartDate !== null)
        || (startDate !== null && oldStartDate === null)
      )
      || (
        (endDate !== null && oldEndDate !== null && endDate.toDateString() !== oldEndDate.toDateString())
        || (endDate === null && oldEndDate !== null)
        || (endDate !== null && oldEndDate === null)
      );

    return (
      <div
        className="date-range-picker-ctn"
        ref={show ? this.wrapperRef : null}
      >
        <label
          className={oldStartDate && oldEndDate ? '' : 'my-placeholder'}
          onClick={this.handleOnClick}
        >
          {oldStartDate && oldEndDate ?
            `${oldStartDate ? displayDate(oldStartDate) : '...'} - ${oldEndDate ? displayDate(oldEndDate) : '...'}`
            :
            'Date Filter'
          }
        </label>
        <div className={`date-range-picker${show ? '' : ' hidden'}`}>
          <div className="btn-ctn">
            <span
              className={changed ? '' : 'disabled'}
              onClick={() => changed ? this.handleOnSubmit() : null}
            >
              filter
            </span>
            <span
              className={startDate || endDate ? '' : 'disabled'}
              onClick={() => startDate || endDate ? this.handleOnChange(null) : null}
            >
              clear
            </span>
          </div>
          <DateRange
            ranges={[selectionRange]}
            onChange={this.handleOnChange}
            startDatePlaceholder='Start'
            endDatePlaceholder='End'
            disabledDay={disabledDay}
          />
        </div>
      </div>
    )
  }

}

DateRangePicker.propTypes = {
  onSelect: PropTypes.func,
  disabledDay: PropTypes.func, // function(Date)
};

export default DateRangePicker;
