import React, {useState, useEffect} from 'react';
import {debounce, cloneDeep} from 'lodash';
import Modal from 'react-bootstrap/Modal';
import {useLoaderData, useParams, useLocation, Link} from 'react-router-dom';
import {
  fnGetEstimateNotes,
  fnAddEstimateNote,
  fnGetLeadRequirements,
  fnAddLeadRequirement,
  fnGetProjectNotes,
  fnAddProjectNote,
} from '../../api';
import {
  logError,
  displayDate,
  displayTime,
  nowDateString,
  responseDataExists,
  routeParamEstimateID,
  routeParamLeadID,
  routeParamProjectID,
} from '../../helpers';
import Filters from '../../components/Filters';

import './style.css';

export const NoteTypeEstimateNote = 'ESTIMATE_NOTE';
export const NoteTypeLeadRequirement = 'LEAD_REQUIREMENT';
export const NoteTypeProjectNote = 'PROJECT_NOTE';

export function Notes() {
  const defaultEstimateNote = () => {
    return {
      estimate_id: null,
      note_id: null,
      note: '',
      ts_created: nowDateString(),
    }
  };

  const defaultLeadRequirement = () => {
    return {
      requirement_id: null,
      requirement: '',
      ts_created: nowDateString(),
    }
  };

  const defaultProjectNote = () => {
    return {
      note_id: null,
      note: '',
      ts_created: nowDateString(),
    }
  };

  const noteType = useLoaderData();
  const params = useParams();
  const location = useLocation();

  function getNoteTypeDetails() {
    let details = {};
    switch (noteType) {
      case NoteTypeEstimateNote:
        details = {
          modalTitle: 'Estimate Note',
          modalField: 'Note',
          noteFieldKey: 'note',
          idFieldKey: 'note_id',
          fnGet: fnGetEstimateNotes,
          fnAdd: fnAddEstimateNote,
          paramKey: routeParamEstimateID,
        };
        break;
      case NoteTypeLeadRequirement:
        details = {
          modalTitle: 'Lead Requirement',
          modalField: 'Requirement',
          noteFieldKey: 'requirement',
          idFieldKey: 'requirement_id',
          fnGet: fnGetLeadRequirements,
          fnAdd: fnAddLeadRequirement,
          paramKey: routeParamLeadID,
        };
        break;
      case NoteTypeProjectNote:
        details = {
          modalTitle: 'Project Note',
          modalField: 'Note',
          noteFieldKey: 'note',
          idFieldKey: 'note_id',
          fnGet: fnGetProjectNotes,
          fnAdd: fnAddProjectNote,
          paramKey: routeParamProjectID,
        };
        break;
      default:
        console.log(`invalid note type: "${noteType}"`);
    }

    return details;
  }

  function defaultNoteObj() {
    let defaultObj = {};
    switch (noteType) {
      case NoteTypeEstimateNote:
        defaultObj = defaultEstimateNote();
        break;
      case NoteTypeLeadRequirement:
        defaultObj = defaultLeadRequirement();
        break;
      case NoteTypeProjectNote:
        defaultObj = defaultProjectNote();
        break;
      default:
        console.log(`invalid note type: "${noteType}"`);
    }

    return defaultObj;
  }

  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState(defaultNoteObj());
  const [modalDetails, setModalDetails] = useState({show: false, isEdit: false});

  function handleOpenModal(note) {
    if (!note) {
      note = defaultNoteObj();
    } else {
      note = cloneDeep(note);
    }

    setNote(note);
    setModalDetails({...modalDetails, show: true});
  }

  function handleCloseModal() {
    setModalDetails({...modalDetails, show: false});
  }

  function handleNoteFieldUpdate(e) {
    const updatedNote = {...note};
    updatedNote[e.target.name] = e.target.value;
    setNote(updatedNote);
  }

  function getNotes(filters = {search: ''}) {
    const data = {search: filters.search};
    if (filters.fromTs && filters.toTs) {
      data.filter_from_ts = filters.fromTs;
      data.filter_to_ts = filters.toTs;
    }

    getNoteTypeDetails().fnGet(params[getNoteTypeDetails().paramKey], data).then((response) => {
      console.log(`${noteType}:\n`, response.data);
      if (responseDataExists(response)) {
        setNotes(response.data.data || []);
      }
    }).catch(logError)
  }

  function addNote() {
    if (note) {
      getNoteTypeDetails().fnAdd(params[getNoteTypeDetails().paramKey], note).then((response) => {
        console.log(`add ${noteType}:\n`, response.data);
        if (response.data && response.data.data) {
          setNotes([response.data.data, ...notes]);
        }
        handleCloseModal()
      }).catch(logError)
    } else {
      handleCloseModal()
    }
  }

  const delayGetNotes = debounce((...args) => getNotes(...args), 1000);

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <div>
      <div className="page-header">
        <div className="left">
          {location && location.state && location.state.back ?
            <Link
              className="back-link"
              to={location.state.back}
            >
              {location.state.backName ? location.state.backName : 'back'}
            </Link>
            :
            null
          }
          <h1>{`${getNoteTypeDetails().modalTitle}s`}</h1>
        </div>
        <div className="right">
          <Filters
            onChangeSearch={delayGetNotes}
            onChangeDateRange={getNotes}
          />
          <button onClick={() => handleOpenModal()}>+</button>
        </div>
      </div>

      <div className="page-body">
        <table className="page-table">
          <thead>
          <tr className="table-headers">
            <th>ID</th>
            <th>{getNoteTypeDetails().modalField}</th>
            <th>Time</th>
          </tr>
          </thead>
          <tbody>
          {notes.map((note, i) => (
            <tr
              className="table-row"
              key={i}
              onClick={() => handleOpenModal(note)}
            >
              <td>{note[getNoteTypeDetails().idFieldKey]}</td>
              <td>{note[getNoteTypeDetails().noteFieldKey]}</td>
              <td>{`${displayDate(note.ts_created)} ${displayTime(note.ts_created, true)}`}</td>
            </tr>
          ))
          }
          </tbody>
        </table>
      </div>

      {/* Modal for new/edit */}
      <Modal
        className="new-edit-modal"
        show={modalDetails.show}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>{modalDetails.isEdit ? 'View' : 'New'} {getNoteTypeDetails().modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <label htmlFor={getNoteTypeDetails().noteFieldKey}>{getNoteTypeDetails().modalField}:</label>
            <input
              type="text"
              name={getNoteTypeDetails().noteFieldKey}
              onChange={handleNoteFieldUpdate}
              value={note[getNoteTypeDetails().noteFieldKey]}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn-cancel"
            onClick={handleCloseModal}
          >
            {!modalDetails.isEdit ? 'Cancel' : 'Close'}
          </button>
          {!modalDetails.isEdit ?
            <button
              className="btn-confirm"
              onClick={addNote}
            >
              Save
            </button>
            :
            null
          }
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Notes;
