import React, {useState, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {fnGetQuote, fnGetInvoice} from '../../api';
import {logError, displayMoney, responseDataExists, routeParamQuoteID, routeParamInvoiceID} from '../../helpers';
import {defaultInvoice} from '../Invoices';

import './style.css';
import logo from './imgs/logo.png';
import customerIcon from './imgs/user.png';
import addressIcon from './imgs/path.png';
import serviceIcon from './imgs/briefcase.png';
import downloadIcon from './imgs/icon-download-grey.png';

export function Invoice() {
  const hstNum = '74306 1947 RT0001';
  const params = useParams();
  const location = useLocation();

  const [invoice, setInvoice] = useState(defaultInvoice());
  const title = invoice.invoice_id ? 'INVOICE' : 'QUOTE';

  function displayDate(value = '') {
    // format DD/MM/YYYY
    const date = new Date(value);
    const y = date.getUTCFullYear();
    let m = date.getUTCMonth();
    const d = date.getUTCDate();

    // month is zero-based
    m += 1;

    return `${d < 10 ? `0${d}` : d}/${m < 10 ? `0${m}` : m}/${y}`;
  }

  function saveInvoiceAsPDF() {
    const currentTitle = document.title;

    // generate invoice pdf name
    const details = invoice.for_details;
    const company = `${(details.company || details.name || '').replaceAll(/[^a-z-\\\\s]+/ig, '')}`;
    document.title = `${company ? company + ' - ' : ''}${title} - ${displayDate(invoice.date)}` || currentTitle;
    window.print();
    document.title = currentTitle;
  }

  function getQuote() {
    fnGetQuote(params[routeParamQuoteID]).then((response) => {
      console.log('quote:\n', response.data);
      if (responseDataExists(response)) {
        setInvoice(response.data.data || defaultInvoice());
      }
    }).catch(logError)
  }

  function getInvoice() {
    fnGetInvoice(params[routeParamInvoiceID]).then((response) => {
      console.log('invoice:\n', response.data);
      if (responseDataExists(response)) {
        setInvoice(response.data.data || defaultInvoice());
      }
    }).catch(logError)
  }

  useEffect(() => {
    if (location && location.state && location.state.data
      && (location.state.data.quote_id || location.state.data.invoice_id)) {
      setInvoice(location.state.data);
    } else if (params[routeParamQuoteID] && !invoice.quote_id) {
      getQuote();
    } else if (params[routeParamInvoiceID] && !invoice.invoice_id) {
      getInvoice();
    }
  }, []);

  return (
    <div className="invoice">
      <div className="download-btn" onClick={saveInvoiceAsPDF}>
        <img src={downloadIcon} alt="download"/>
      </div>
      <div className="invoice-header">
        <div className="invoice-banner">
          <img alt="logo" src={logo}/>
          <div className="right-ctn right-align">
            <h1>{title}</h1>
            <ul className="address">
              <li>29 Manitou Dr Unit 1C</li>
              <li>Kitchener, Ontario</li>
              <li>N2C 1K9</li>
            </ul>
          </div>
        </div>
        <div className="info-ctn">
          <hr/>
          <div className="info">
            <div className="info-block">
              <div className="right-ctn">
                <span className="title">{title} DATE</span>
                <p>{displayDate(invoice.date)}</p>
              </div>
            </div>
            <div className="info-block">
              <div className="right-ctn">
                <span className="title">{invoice.invoice_id ? 'DUE DATE' : 'DATE VALID'}</span>
                <p>{displayDate(invoice.date_valid)}</p>
              </div>
            </div>
            <div className="info-block">
              <div className="right-ctn">
                <span className="title">{title} NO:</span>
                <p>#{invoice.invoice_id ? invoice.invoice_num : invoice.quote_num}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <table>
        {/* placeholder to create space for header when printing */}
        <thead>
        <tr>
          <td>
            <div className="invoice-header-space">&nbsp;</div>
          </td>
        </tr>
        </thead>

        <tbody>
        <tr>
          <td>
            <div className="invoice-body">
              <div className="contact-ctn">
                <div className="contact-block">
                  <img alt="icon-customer" src={customerIcon}/>
                  <span className="title">CUSTOMER DETAILS</span>
                  <ul>
                    <li>{invoice.for_details.company}</li>
                    <li>{invoice.for_details.name}</li>
                    <li>{invoice.for_details.phone}</li>
                  </ul>
                </div>
                <div className="contact-block">
                  <img alt="icon-customer-address" src={addressIcon}/>
                  <span className="title">CUSTOMER ADDRESS</span>
                  <ul>
                    <li>{invoice.for_details.address}</li>
                    <li>{invoice.for_details.town_province}</li>
                    <li>{invoice.for_details.postal_code}</li>
                  </ul>
                </div>
                <div className="contact-block">
                  <img alt="icon-service-address" src={serviceIcon}/>
                  <span className="title">SERVICE ADDRESS</span>
                  <ul>
                    <li>{invoice.at_details.address}</li>
                    <li>{invoice.at_details.town_province}</li>
                    <li>{invoice.at_details.postal_code}</li>
                  </ul>
                </div>
              </div>
              <div className="notes-ctn">
                <div className="left-ctn">
                  <span className="title">Notes</span>
                  <p>{invoice.notes}</p>
                </div>
                <div className="right-ctn">
                  <span className="title">Amount Due</span>
                  <span className="amount-large">{displayMoney(invoice.subtotal + invoice.tax)}</span>
                </div>
              </div>
              <div className="items-ctn">
                <table>
                  <thead>
                  <tr>
                    <th style={{width: '25%'}}>Item</th>
                    <th>Description</th>
                    <th style={{width: '10%'}}>Rate</th>
                    <th style={{width: '5%'}}>Qty</th>
                    <th style={{width: '20%'}}>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {invoice.items.map((item, i) => (
                    <tr key={i}>
                      <td>{item}</td>
                      <td>{invoice.descriptions[i]}</td>
                      <td>{displayMoney(invoice.prices[i])}</td>
                      <td>{invoice.quantities[i]}</td>
                      <td>{displayMoney(invoice.prices[i] * invoice.quantities[i])}</td>
                    </tr>
                  ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
        </tbody>

        {/* placeholder to create space for footer when printing */}
        <tfoot>
        <tr>
          <td>
            <div className="invoice-footer-space">&nbsp;</div>
          </td>
        </tr>
        </tfoot>
      </table>

      <div className="invoice-footer">
        <div className="top-ctn">
          <div className="left-ctn">
            Thank You for your business!
          </div>
          <div className="right-ctn">
            <div className="titles">
              <span className="hst-num">({hstNum})<span className="title">HST:</span></span>
              <span className="title">TOTAL:</span>
            </div>
            <div className="amounts">
              <span>{displayMoney(invoice.tax)}</span>
              <span>{displayMoney(invoice.subtotal)}</span>
            </div>
          </div>
        </div>
        <div className="bottom-ctn">
          <span className="amount-large">Grand Total: {displayMoney(invoice.subtotal + invoice.tax)}</span>
        </div>
      </div>
    </div>
  )
}

export default Invoice;
